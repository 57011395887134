<template>
  <div>
    <PageTitleVue> Produtos </PageTitleVue>

    <ContainerVue>
      <ProductTable :items="dataTable" :itemsPerPage="10">
        <thead>
          <tr>
            <TableHeadFieldVue>
              <span class="mb-1.5"> Produto / Serviço </span>
            </TableHeadFieldVue>

            <TableHeadFieldVue>
              <span class="mb-1.5"> Estoque </span>
            </TableHeadFieldVue>

            <TableHeadFieldVue>
              <span class="mb-1.5"> Valor </span>
            </TableHeadFieldVue>

            <TableHeadFieldVue>
              <span class="mb-1.5"> Observação </span>
            </TableHeadFieldVue>
          </tr>
        </thead>
      </ProductTable>
      <FloatCreateVue 
        :link="'products-new'" 
        :menuActive="'Gestão'"
      />
    </ContainerVue>
  </div>
</template>

<script setup>
import TableHeadFieldVue from '@/components/Tables/TableHeadField.vue'
import FloatCreateVue from '@/components/Buttons/FloatCreate.vue'
import PageTitleVue from '@/components/Titles/PageTitle.vue'
import ContainerVue from '@/components/Container.vue'
import ProductTable from './components/Table.vue'
import { onBeforeMount, ref } from 'vue'
import { storeApp } from '@/store'
import ProductService from './services/product.service'
import { useToast } from 'vue-toastification'

const dataTable = ref([])
const toast = useToast()
const appStore = storeApp.state

onBeforeMount(async () => {
  appStore.loading = true
  await getProducts()
  appStore.loading = false
})

async function getProducts () {
  await ProductService.getAllProducts()
    .then(res => {
      dataTable.value = res.data
    }).catch(err => {
      toast('Algo deu errado ao buscar produtos', {
        toastClassName: 'toast-error'
      })
    })
}

</script>

import { createStore } from 'vuex'

export const dataUser = createStore({
  state: () => {
    return {
      name: undefined,
      role: undefined,
      email: undefined,
      phone: undefined,
      haircuts_count: undefined,
      collabs: undefined,
      password: {
        old: undefined,
        newPass: undefined,
        confirm: undefined
      }
    }
  },
  mutations: {
    reset (state) {
      state.name = undefined
      state.role = undefined
      state.email = undefined
      state.phone = undefined
      state.password.old = undefined
      state.password.newPass = undefined
      state.password.confirm = undefined
    },
    updateUserFields (state, payload) {
      Object.assign(state, payload)
    }
  }
})

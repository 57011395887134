import { http } from '@/http'

const PATH = 'app/'

class HomeService {
  async getDataChartTable () {
    return http.get(PATH.concat('worker-performance'))
  }
}

export default new HomeService()

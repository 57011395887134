import { createStore } from 'vuex'

export const dataReleases = createStore({
  state: () => {
    return {
      clients: undefined,
      products: undefined
    }
  },
  mutations: {
    reset (state) {
      state.clients = undefined
      state.products = undefined
    },
    setFields (state, payload) {
      Object.assign(state, payload)
    }
  }
})

<template>
  <div class="p-6 flex min-h-[53vh]">
    <div class="container max-w-screen-lg mx-auto">
      <div>
        <div class="rounded shadow-lg p-4 px-4 md:p-8 mb-6 top-0">
          <div class="grid gap-4 gap-y-2 text-sm grid-cols-1 lg:grid-cols-3">
            <div class="text-left hidden md:block">
              <p class="mb-4 font-medium text-lg text-gray-800">
                {{ props.descriptionTitle }}
              </p>
              <p class="text-gray-500">
                Campos com <span class="text-red-500">*</span> são obrigatórios
              </p>
            </div>

            <div id="form-fields" class="lg:col-span-2">
              <div class="grid gap-4 gap-y-2 text-sm grid-cols-1 md:grid-cols-5">
                <div class="md:col-span-3">
                  <label class="text-left flex pl-1" for="name">
                    Informe o cliente <a class="text-red-600 ml-1">*</a>
                  </label>
                  <div
                    class="h-10 bg-gray-50 flex border border-gray-200 rounded items-center mt-1 w-full"
                  >
                    <SimpleSelect
                      class="px-1 py-2 appearance-none outline-none text-gray-800 w-full bg-transparen"
                      :data="props.clients"
                      @selected-data="(val) => (store.clients = val)"
                    />
                  </div>

                  <span name="clients" class="text-red-600 text-sm" />
                </div>

                <div class="md:col-span-2">
                  <label class="text-left flex pl-1" for="cellphone">
                    Produto / Serviço <a class="text-red-600 ml-1">*</a>
                  </label>
                  <div
                    class="h-10 bg-gray-50 flex border border-gray-200 rounded items-center mt-1 w-full"
                  >
                    <ProductSimpleSelect
                      class="px-1 py-2 appearance-none outline-none text-gray-800 w-full bg-transparen"
                      :data="props.products"
                      @selected-data="(val) => mountItemsToSend(val)"
                    />
                  </div>

                  <span name="products" class="text-red-600 text-sm" />
                </div>

                <!-- produtos com estoque precisam informar a quantidade retirada -->
                <!-- <div class="md:col-span-2">
                  <label class="text-left flex pl-1" for="name">
                    Informe a quantidade <a class="text-red-600 ml-1">*</a>
                  </label>
                  <div
                    class="h-10 bg-gray-50 flex border border-gray-200 rounded items-center mt-1"
                  >
                    <input
                      v-model="store.name"
                      type="number"
                      name="name"
                      class="px-1 py-2 appearance-none outline-none text-gray-800 w-full bg-transparent"
                    />
                    <CleanInputButton
                      @click="store.name = undefined"
                    />
                  </div>

                  <span name="name" class="text-red-600 text-sm" />
                </div> -->

              </div>
              <div v-if="dataItems" class="mb-2 w-full p-1">
                <div
                  class="inline-flex flex-wrap"
                  v-for="(item, index) in dataItems"
                  :key="index"
                >
                  <ArrayItems
                    :item="item?.name"
                    @removeItem="removeItemByDataItems(index)"
                  />
                </div>
              </div>

            </div>
          </div>
          <div class="flex justify-center mt-8">
            <slot />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import ArrayItems from './../components/ArrayItems.vue'
import SimpleSelect from '@/components/Select/SimpleSelect.vue'
import ProductSimpleSelect from './../components/ProductSimpleSelect.vue'
import { dataReleases } from '../store/'
import { defineProps, ref } from 'vue'

const store = dataReleases.state
const dataItems = ref([])

const props = defineProps({
  descriptionTitle: String,
  clients: Array,
  products: Array
})

function mountItemsToSend (item) {
  dataItems.value.push(item)
  store.products = dataItems.value
}

function removeItemByDataItems (index) {
  dataItems.value.splice(index, 1)
}

</script>

import ReleasesView from '../ReleasesView.vue'

export const releasesRoutes = [
  {
    path: '/releases',
    name: 'releases',
    component: ReleasesView,
    beforeEnter: (to, from, next) => {
      if (!localStorage.__access) next({ name: 'login' })
      next()
    }
  }
]

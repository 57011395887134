import CollabView from '../CollabView.vue'
import CollabRegister from '../layouts/Register.vue'
import CollabUpdate from '../layouts/Update.vue'

export const collabRoutes = [
  {
    path: '/collab',
    name: 'collaborators',
    component: CollabView,
    beforeEnter: (to, from, next) => {
      if (!localStorage.__access) next({ name: 'login' })
      next()
    },
    meta: {
      key: 'view_permissions'
    }
  },
  {
    path: '/collab/new',
    name: 'new-collab',
    component: CollabRegister,
    beforeEnter: (to, from, next) => {
      if (!localStorage.__access) next({ name: 'login' })
      next()
    },
    meta: {
      key: 'view_permissions'
    }
  },
  {
    path: '/collab/edit/:id',
    name: 'edit-collab',
    component: CollabUpdate,
    beforeEnter: (to, from, next) => {
      if (!localStorage.__access) next({ name: 'login' })
      next()
    },
    meta: {
      key: 'view_permissions'
    }
  }
]

import { http } from '@/http'

const PATH = 'app/'

class ReleasesService {
  async getDataFields () {
    return http.get(PATH.concat('data-fields'))
  }

  async register (data) {
    return http.post(PATH.concat('new-haircut'), data, {
      headers: {
        __user: localStorage.getItem('__uuid')
      }
    })
  }
}

export default new ReleasesService()

import { createRouter, createWebHistory } from 'vue-router'
import { homeRoute } from '@/modules/home/routes'
// import { loginRoute } from '@/modules/auth/routes'
import { http } from '@/http'
import { useToast } from 'vue-toastification'
import { storeApp } from '@/store'
import { profileRoute } from '@/modules/profile/routes'
import { clientRoutes } from '@/modules/clients/routes'
import { movimentRoutes } from '@/modules/moviment/routes'
import { releasesRoutes } from '@/modules/releases/routes'
import { productsRoutes } from '@/modules/products/routes'
import { collabRoutes } from '@/modules/collaborators/routes'
import { companyRoute } from '@/modules/company/routes'
import { costRoute } from '@/modules/costs/routes'
import { loginRoutes } from '@/modules/login/router'
import authService from '@/modules/auth/services/auth.service'

const toast = useToast()
const routes = [
  ...homeRoute,
  // ...loginRoute,
  ...profileRoute,
  ...clientRoutes,
  ...movimentRoutes,
  ...releasesRoutes,
  ...productsRoutes,
  ...collabRoutes,
  ...companyRoute,
  ...costRoute,
  ...loginRoutes
]

const router = createRouter({
  history: createWebHistory('#'),
  routes
})

router.beforeEach(async (to, from, next) => {
  storeApp.state.loading = true

  if(to.name === 'logout') {
    return authService.logout()
      .then((res) => {
        storeApp.dispatch('hiddenMenuUserLoggedOut')

        toast('Até logo!', { toastClassName: 'toast-success' })
        next({name: 'login'})
      })
      .catch((err) => {
        toast('Algo deu errado!', { toastClassName: 'toast-error' })
        next({name: 'login'})
      })
  }
  // verifica as permissões para acessar a página
  if (to.meta.key) {
    const userPermissions = localStorage.getItem('__permissions')

    if (userPermissions && !userPermissions.split(',').includes(to.meta.key)) {
      if (from.name !== 'login') {
        toast('Você não tem permissão para acessar essa página', {
          toastClassName: 'toast-info'
        })
      }
      next({ name: 'releases' })
    }
  }

  const routeNamesToCheck = ['login', 'login-sign-in', 'login-sign-up', 'login-forgot', 'confirm-code'];
  const isMainRouteValid = routeNamesToCheck.includes(to?.name);
  const isChildRouteValid = to?.matched.some(record =>
    record?.children && record?.children.some(child => routeNamesToCheck.includes(child?.name))
  );
  
  if (! isMainRouteValid || ! isChildRouteValid) {
    try {
      await http.get(process.env.VUE_APP_API_URL.concat('auth/me'))
      storeApp.state.loading = false
      next()
    } catch (error) {
      toast(
        'Sessão expirada!', {
          toastClassName: 'toast-error'
        })
      
      storeApp.state.loading = false
      storeApp.dispatch('hiddenMenuUserLoggedOut')

      if(to.query.indicatedBy){
        next({ name: 'login-sign-up', query: { indicatedBy: to.params.indicatedBy } })
      } else {
        next({ name: 'login' })
      }
    }
  } else {
    storeApp.state.accessToken.clear()
    storeApp.state.loading = false
    next()
  }
})

export default router

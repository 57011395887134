import { http } from '@/http'

const PATH = 'app/clients/'

class ClientService {
  async getAllClients () {
    return http.get(PATH.concat('all'))
  }

  async register (data) {
    return http.post(PATH.concat('new'), data)
  }

  async getClientById (id) {
    return http.get(PATH.concat(id).concat('/client'))
  }

  async editClient (id, data) {
    return http.post(PATH.concat(id).concat('/edit'), data)
  }

  async deleteClient (id) {
    return http.patch(PATH.concat(id).concat('/disable'))
  }

  // async getReports () {
  //   return http.get(PATH.concat('reports'))
  // }
}

export default new ClientService()

import ProductsView from '../ProductsView.vue'
import ProductsRegister from '../layouts/Register.vue'
import ProductsUpdate from '../layouts/Update.vue'

export const productsRoutes = [
  {
    path: '/products',
    name: 'products',
    component: ProductsView,
    beforeEnter: (to, from, next) => {
      if (!localStorage.__access) next({ name: 'login' })
      next()
    },
    meta: {
      key: 'view_products'
    }
  },
  {
    path: '/product/new',
    name: 'products-new',
    component: ProductsRegister,
    beforeEnter: (to, from, next) => {
      if (!localStorage.__access) next({ name: 'login' })
      next()
    },
    meta: {
      key: 'add_products'
    }
  },
  {
    path: '/product/edit/:id',
    name: 'product-edit',
    component: ProductsUpdate,
    beforeEnter: (to, from, next) => {
      if (!localStorage.__access) next({ name: 'login' })
      next()
    },
    meta: {
      key: 'edit_products'
    }
  }
]

import CostView from '../CostView.vue'

export const costRoute = [
  {
    path: '/costs',
    name: 'costs',
    alias: '/costs',
    component: CostView,
    beforeEnter: (to, from, next) => {
      if (!localStorage.__access) next({ name: 'login' })
      next()
    },
    meta: {
      key: 'view_costs'
    }
  }
]

<template>
  <div>
    <PageTitleVue> Meus dados </PageTitleVue>
    <ContainerVue>
      <Form id="form-fields" :description-title="'Dados da empresa'">
        <div class="w-full flex justify-center mt-4">
          <CreateButton
            @click="saveData"
            :icon="'fa-solid fa-check'"
            :label="'Salvar'"
          />
        </div>
      </Form>
    </ContainerVue>
    <FloatBackPage />
  </div>
</template>

<script setup>
import FloatBackPage from '@/components/Buttons/FloatBackPage.vue'
import PageTitleVue from '@/components/Titles/PageTitle.vue'
import CreateButton from '@/components/Buttons/Create.vue'
import CompanyService from '../services/company.service'
import ContainerVue from '@/components/Container.vue'
import Form from './Form.vue'
import { setFormFieldsError, validateFormField } from '@/helpers/formFieldsHandler'
import { useToast } from 'vue-toastification'
import { useRoute } from 'vue-router'
import { dataUser } from '../store'
import { storeApp } from '@/store'
import { onMounted } from 'vue'
import * as yup from './../../../validator'

const store = dataUser
const appStore = storeApp.state
const meUuid = useRoute()
const toast = useToast()

const schema = yup.object().shape({
  company: yup.object().shape({
    name: yup.string().required().label('Nome Fantasia').max(100).min(2),
    cnpj: yup.string().required().label('CNPJ').min(18),
    city: yup.string().required().label('Cidade'),
    number: yup.number().integer().required().label('Número').min(1)
  })
})

onMounted(async () => {
  appStore.loading = true
  store.commit('reset')
  await Promise.all([
    getDataCompany()
  ])
  appStore.loading = false
})

async function getDataCompany () {
  await CompanyService.getMeCompany(meUuid.params.id)
    .then((res) => {
      store.state.company.name = res.data.name
      store.state.company.cnpj = res.data.CNPJ
      store.state.company.city = res.data.city
      store.state.company.address = res.data.address
      store.state.company.country = res.data.country
      store.state.company.number = res.data.number
      store.state.imgPath = res.data.photo
    })
    .catch((err) => {
      toast('Algo deu errado ao buscar dados da empresa!', {
        toastClassName: 'toast-error'
      })
    })
}

async function saveData () {
  appStore.loading = true

  const validate = (await validateFormField(schema, store.state))
  if (validate && validate.length > 0) {
    setFormFieldsError(validate)
    appStore.state.loading = false
    return
  }

  await Promise.all([
    editCompany()
  ])
  appStore.loading = false
}

async function editCompany () {
  // se caso exista uma foto, remova e troque pela nova
  if (
    store.state.company.photo &&
    typeof store.state.company.photo === 'object' &&
    typeof store.state.imgPath === 'string' &&
    store.state.imgPath !== '[]'
  ) {
    await CompanyService.editImage(
      store.state.company.photo,
      store.state.imgPath
    )
      .then((res) => {
        store.state.imgPath = res.data
        store.state.company.photo = res.data
        
        storeApp.dispatch('setCompanyLogo', res.data)
      })
      .catch((err) => {
        toast('Algo deu errado ao subir arquivo!', {
          toastClassName: 'toast-warning'
        })
      })
  }
  // adicione uma nova foto
  else if (
    store.state.company.photo &&
    typeof store.state.company.photo !== 'string'
  ) {
    await CompanyService.saveImage(store.state.company.photo)
      .then((res) => {
        store.state.imgPath = res.data
        store.state.company.photo = res.data
        
        storeApp.dispatch('setCompanyLogo', res.data)
      })
      .catch((err) => {
        toast('Algo deu errado ao subir arquivo!', {
          toastClassName: 'toast-warning'
        })
      })
  }
  // passa o caminho da imagem
  else {
    store.state.company.photo = store.state.imgPath
  }

  await CompanyService.updateMeCompany(meUuid.params.id, store.state.company)
    .then((res) => {
      if (res.data.success) {
        toast('Dados da empresa atualizados com sucesso!', {
          toastClassName: 'toast-success'
        })
      }
    })
    .catch((err) => {
      toast('Algo deu errado ao atualizar dados!', {
        toastClassName: 'toast-error'
      })
    })
}
</script>

<style></style>

import { http } from '@/http'

const PATH = 'app/collabs/'

class CollabService {
  async getAllColaborators () {
    return http.get(PATH.concat('all'))
  }

  async register (data) {
    return http.post(PATH.concat('new'), data, {
      headers: {
        __user: localStorage.getItem('__uuid')
      }
    })
  }

  async getCollabById (id) {
    return http.get(PATH.concat(id).concat('/collab'))
  }

  async editCollaborator (id, data) {
    return http.post(PATH.concat(id).concat('/edit'), data, {
      headers: {
        __user: localStorage.getItem('__uuid')
      }
    })
  }

  async deleteCollaborator (id) {
    return http.patch(PATH.concat(id).concat('/disable'))
  }

  async getPermissions () {
    return http.get(PATH.concat('permissions'))
  }
}

export default new CollabService()

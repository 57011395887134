<template>
  <div class="overflow-x-auto py-2 sm:px-6 lg:px-2 rounded-md">
    <table class="w-full">
      <slot />
      <tbody>
        <tr v-for="(row, index) in paginatedItems" :key="index" class="hover:shadow-lg hover:-translate-y-1 hover:-translate-x-1 transition-all z-9" :class="index % 2 === 0 ? 'bg-gray-100' : 'bg-white'">
          <td class="text-left whitespace-nowrap pr-3 text-sm text-gray-900 sm:pl-0 cursor-pointer min-w-[10px]">
            <div :class="{ 'text-center': center }">
              <span class="pl-2">
                {{ row.name }}
                <!-- name -->
              </span>
            </div>
          </td>
          <td class="text-left whitespace-nowrap pr-3 text-sm text-gray-900 sm:pl-0 cursor-pointer min-w-[10px]">
            <div :class="{ 'text-center': center }">
              <span class="pl-2">
                {{ row.amount }}
                <i v-if="!row.amount" class="text-gray-400">
                  não informado
                </i>
              </span>
            </div>
          </td>
          <td class="text-left whitespace-nowrap pr-3 text-sm text-gray-900 sm:pl-0 cursor-pointer min-w-[10px]">
            <div :class="{ 'text-center': center }">
              <span class="pl-2">
                {{ row.price }}
              </span>
            </div>
          </td>
          <td class="text-left whitespace-nowrap pr-3 text-sm text-gray-900 sm:pl-0 cursor-pointer min-w-[10px]">
            <div :class="{ 'text-center': center }">
              <span class="pl-2">
                {{ row.description }}
                <i v-if="!row.description" class="text-gray-400">
                  sem observação
                </i>
              </span>
            </div>
          </td>

          <td class="flex justify-center whitespace-nowrap py-1 pr-2 text-sm font-medium text-gray-900 sm:pl-0">
            <ActionsButtonVue
              :link="url.edit + items[index].id"
              @delete="deleteProduct(items[index].id)"
            />
            <!-- @getReport="generateReport(dataTable[index].id)" -->
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div v-if="items.length > 10">
    <section class="mt-4 flex justify-center">
      <Create
        class="mr-4"
        @click="prevPage"
        :disabled="currentPage === 1"
        :label="'Anterior'"
        :icon="'fa-solid fa-arrow-left'"
      />

      <Create
        class="ml-4"
        @click="nextPage"
        :disabled="currentPage === totalPages"
        :label="'Próximo'"
        :icon="'fa-solid fa-arrow-right'"
      />
    </section>

    <p>Página {{ currentPage }} de {{ totalPages }}</p>
  </div>
</template>

<script>
import ActionsButtonVue from '../../../components/Tables/ActionsButton.vue'
import ProductService from '../services/product.service'
import Create from '@/components/Buttons/Create.vue'
import { useToast } from 'vue-toastification'
import { storeApp } from '@/store'

export default {
  name: 'ProductTable',
  components: {
    ActionsButtonVue,
    Create
  },
  props: {
    center: { Boolean, default: false },
    items: { type: Array, required: true },
    itemsPerPage: { type: Number, default: 10 }
  },
  data () {
    return {
      url: {
        edit: '/product/edit/'
      },
      toast: useToast(),
      showModal: false,
      modalTitle: 'Selecione o relatório que deseja',
      currentEmployee: {},
      currentPage: 1
    }
  },
  computed: {
    totalPages () {
      return Math.ceil(this.items.length / this.itemsPerPage)
    },
    paginatedItems () {
      const start = (this.currentPage - 1) * this.itemsPerPage
      const end = start + this.itemsPerPage
      return this.items.slice(start, end)
    }
  },
  methods: {
    // closeModal() {
    //   this.showModal = false
    //   this.currentEmployee = {}
    // },
    // showReport(employeeData) {
    //   this.currentEmployee = employeeData
    //   this.showModal = true
    // },
    // async generateReport (id) {
    //   storeApp.state.loading = true

    //   const url = `${process.env.VUE_APP_API_URL}${PATH}reports/${id}`
    //   const report = new PdfCreate(url)

    //   return await report.fetchPdf().finally(() => {
    //     storeApp.state.loading = false
    //   })
    // },
    // async generateBadges (id) {
    //   storeApp.state.loading = true

    //   const url = `${process.env.VUE_APP_API_URL}${PATH}${id}/badges`
    //   const report = new PdfCreate(url)

    //   return await report.fetchPdf().finally(() =>{
    //     storeApp.state.loading = false
    //   })
    // },
    deleteProduct (id) {
      storeApp.state.loading = true
      const confirm = window.confirm('Deseja realmente remover esse produto ?')
      if (confirm) {
        ProductService.deleteProduct(id).then(res => {
          if (res.data.success) {
            this.toast(res.data.success, {
              toastClassName: 'toast-success'
            })
          }
          window.location.reload()
        }).catch(err => {
          this.toast(err.response.data.error
            , { toastClassName: 'toast-error' })
        })
      }
      storeApp.state.loading = false
    },
    prevPage () {
      if (this.currentPage > 1) {
        this.currentPage--
      }
    },
    nextPage () {
      if (this.currentPage < this.totalPages) {
        this.currentPage++
      }
    }
  }
}
</script>

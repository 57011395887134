<template>
    <div class=" flex items-center justify-center">
      <div class="container max-w-screen-lg mx-auto">
        <div>
          <div class="bg-white rounded shadow-lg p-4 px-4 md:p-8 mb-6">
            <div class="grid gap-4 gap-y-2 text-sm grid-cols-1 lg:grid-cols-3">
              <div class="text-left hidden md:block">
                <p class="mb-4 font-medium text-lg text-gray-800">
                  {{ props.descriptionTitle }}
                </p>
              </div>

              <div class="lg:col-span-2">
                <div
                  class="grid gap-4 gap-y-2 text-sm grid-cols-1 md:grid-cols-5"
                >
                  <div v-show="! props.create" class="md:col-span-3">
                    <label class="text-left flex pl-1" for="name">
                      Senha anterior
                    </label>
                    <div
                      class="h-10 bg-gray-50 flex border border-gray-200 rounded items-center mt-1"
                    >
                      <input
                        v-model="store.password.old"
                        type="password"
                        name="password.old"
                        class="px-1 py-2 appearance-none outline-none text-gray-800 w-full bg-transparent"
                      />
                      <CleanInputButton
                        @click="store.password.old = null"
                      />
                    </div>

                    <span name="password.old" class="text-red-600 text-sm" />
                  </div>

                  <div :class="{ 'md:col-span-2': ! create, 'md:col-span-5': create }">
                    <label class="text-left flex pl-1" for="phone">
                      Nova senha
                    </label>

                    <div class="h-10 bg-gray-50 flex border border-gray-200 rounded items-center mt-1">
                      <div class="relative w-11/12 pr-2">
                        <input
                          v-model="store.password.newPass"
                          :type="inputTypePassowrd"
                          name="password.newPass"
                          class="px-1 py-2 pr-2 appearance-none outline-none text-gray-800 w-full bg-transparent"
                        />

                        <label
                          @click="togglePasswordVisibility"
                          class="absolute top-2 cursor-pointer"
                        >
                          <i v-if="showPassword" class="fas fa-eye text-gray-600"></i>
                          <i v-else class="fas fa-eye-slash text-gray-600"></i>
                        </label>
                      </div>

                    </div>
                    <span name="password.newPass" class="text-red-600 text-sm" />

                  </div>

                  <div class="md:col-span-5">
                    <label class="text-left flex pl-1" for="password.confirm">
                      Confirmar senha
                    </label>

                    <div class="h-10 bg-gray-50 flex border border-gray-200 rounded items-center mt-1">
                      <div class="relative w-full pr-7">
                        <input
                          v-model="store.password.confirm"
                          :type="inputTypePassowrdConfirm"
                          name="password.confirm"
                          class="px-1 py-2 appearance-none outline-none text-gray-800 w-full bg-transparent"
                        />

                        <label
                          @click="togglePasswordConfirmVisibility"
                          class="absolute top-2 cursor-pointer"
                        >
                          <i v-if="showPasswordConfirm" class="fas fa-eye text-gray-600"></i>
                          <i v-else class="fas fa-eye-slash text-gray-600"></i>
                        </label>
                      </div>

                    </div>

                    <span name="password.confirm" class="text-red-600 text-sm" />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="p-2 mt-1 mb-1" />

          <div>
            <slot />
          </div>

        </div>
      </div>
    </div>
  </template>

<script setup>
import CleanInputButton from '../component/CleanInputButton.vue'
import { dataUser } from '../store'
import { computed, ref } from 'vue'

const store = dataUser.state
const showPassword = ref(false)
const showPasswordConfirm = ref(false)

const props = defineProps({
  descriptionTitle: String,
  create: Boolean
})

const inputTypePassowrd = computed(() => (showPassword.value ? 'text' : 'password'))
const inputTypePassowrdConfirm = computed(() => (showPasswordConfirm.value ? 'text' : 'password'))

const togglePasswordVisibility = () => {
  showPassword.value = !showPassword.value
}

const togglePasswordConfirmVisibility = () => {
  showPasswordConfirm.value = !showPasswordConfirm.value
}

</script>

<template>
  <div>
    <PageTitleVue>
      Clientes
    </PageTitleVue>

    <ContainerVue>
      <Paginate :items="dataTable" :itemsPerPage="10">
        <thead>
          <tr>
            <TableHeadFieldVue>
              <span class="mb-1.5"> Nome </span>
            </TableHeadFieldVue>

            <TableHeadFieldVue>
              <span class="mb-1.5"> Celular </span>
            </TableHeadFieldVue>

            <TableHeadFieldVue>
              <span class="mb-1.5"> Instagram </span>
            </TableHeadFieldVue>

            <TableHeadFieldVue>
              <span class="mb-1.5"> Facebook </span>
            </TableHeadFieldVue>
          </tr>
        </thead>
      </Paginate>
      <FloatCreateVue 
        :link="'clients-new'" 
        :menuActive="'Gestão'"
        :icon="'fa-solid fa-user-plus'" 
      />
    </ContainerVue>
  </div>
</template>

<script setup>
import TableHeadFieldVue from '@/components/Tables/TableHeadField.vue'
import FloatCreateVue from '@/components/Buttons/FloatCreate.vue'
import PageTitleVue from '@/components/Titles/PageTitle.vue'
import ClientService from './services/clients.service'
import ContainerVue from '@/components/Container.vue'
import Paginate from './components/Paginate.vue'
import { storeApp } from '@/store'
import { onMounted, ref } from 'vue'
import { useToast } from 'vue-toastification'

const appStore = storeApp.state
const dataTable = ref([])
const toast = useToast()

onMounted(async () => {
  appStore.loading = true
  await getClients()
  appStore.loading = false
})

async function getClients () {
  return await ClientService.getAllClients()
    .then(res => {
      dataTable.value = res.data
    }).catch(err => {
      toast('Algo deu errado ao buscar clientes', {
        toastClassName: 'toast-error'
      })
    })
}

</script>

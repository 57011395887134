<template>
  <div>
    <PageTitle> Novo serviço </PageTitle>

    <ContainerVue>
      <Form
        :descriptionTitle="'Registre um novo serviço'"
        :clients="clientsField"
        :products="productsField"
      >
        <CreateButton
          @click="newHaircut"
          :icon="'fa-solid fa-hand-scissors'"
          :label="'Registrar'"
        />
      </Form>
    </ContainerVue>
  </div>
</template>

<script setup>
import CreateButton from '@/components/Buttons/Create.vue'
import PageTitle from '@/components/Titles/PageTitle.vue'
import ReleasesService from './services/releases.service'
import ContainerVue from '@/components/Container.vue'
import Form from './layouts/Form.vue'
import { setFormFieldsError, validateFormField } from '@/helpers/formFieldsHandler'
import { useToast } from 'vue-toastification'
import { dataReleases } from './store'
import { onMounted, ref } from 'vue'
import { storeApp } from '@/store'
import * as yup from '@/validator'

const toast = useToast()
const appStore = storeApp
const store = dataReleases
const clientsField = ref([])
const productsField = ref([])

const form = yup.object().shape({
  clients: yup.object().required('É necessário informar um cliente').label('Cliente'),
  products: yup.array().required('É necessário informar um produto ou serviço').label('Produto')
})

onMounted(async () => {
  appStore.state.loading = true
  await getProductsAndClients()
  appStore.state.loading = false
})

async function getProductsAndClients () {
  await ReleasesService.getDataFields()
    .then(res => {
      clientsField.value = res.data.clients
      productsField.value = res.data.products
    }).catch(err => {
      toast(err.response.data.error, {
        toastClassName: 'toast-error'
      })
    })
}

async function newHaircut () {
  appStore.state.loading = true

  const validate = (await validateFormField(form, store.state))

  if (validate && validate.length > 0) {
    setFormFieldsError(validate)
    appStore.state.loading = false
    return
  }

  await ReleasesService.register(store.state)
    .then(res => {
      appStore.state.loading = false

      if (res.data.success) {
        toast(res.data.success, {
          toastClassName: 'toast-success'
        })
      }
    }).catch(err => {
      appStore.state.loading = false

      toast(err.response.data.error, {
        toastClassName: 'toast-error'
      })
    })
}

</script>

<style>

</style>

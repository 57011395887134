<template>
  <div>
    <div class="fadeIn first mb-5">
      <img
        :src="appImage"
        alt="BarbApp"
        class="w-32 h-28 mx-auto rounded-full"
      />
    </div>
    <div id="form-fields" class="lg:col-span-2">

      <div class="relative">
        <input 
          class="fadeIn second" 
          placeholder="Defina uma senha forte"
          name="password"
          v-model="store.password"
          :type="inputType"
        />
        <br>
        <span name="password" class="text-red-600 text-sm"></span>

        <label
          @click="togglePasswordVisibility"
          class="absolute top-1/2 transform -translate-y-1/2 cursor-pointer"
          :class="[
            isMobile ? 'right-8' : 'right-12'
          ]"
        >
          <i v-if="showPassword" class="fas fa-eye text-gray-600"></i>
          <i v-else class="fas fa-eye-slash text-gray-600"></i>
        </label>
      </div>

      <div class="relative">
        <input 
          class="fadeIn second" 
          placeholder="Confirme sua senha"
          name="confirm"
          v-model="store.confirm"
          :type="inputTypeConfirm"
        />
        <br>
        <span name="confirm" class="text-red-600 text-sm"></span>

        <label
          @click="togglePasswordConfirmVisibility"
          class="absolute top-1/2 transform -translate-y-1/2 cursor-pointer"
          :class="[
            isMobile ? 'right-8' : 'right-12'
          ]"
        >
          <i v-if="showPasswordConfirm" class="fas fa-eye text-gray-600"></i>
          <i v-else class="fas fa-eye-slash text-gray-600"></i>
        </label>
      </div>

      <div class="p-2 flex justify-center fadeIn fourth">
        <CreateButton
          class="mt-5"
          @click="restorePassword()"
          :label="'Redefinir senha'"
          :icon="'fa-solid fa-key'"
          :maxSize="true"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import CreateButton from "@/components/Buttons/Create.vue";
import AuthService from "../services/auth.service";
import ForgotPassword from "./ForgotPassword.vue";
import { setFormFieldsError, validateFormField } from "@/helpers/formFieldsHandler";
import { useToast } from 'vue-toastification';
import { dataAuth } from "../store";
import { storeApp } from '@/store';
import * as yup from '@/validator';
import { markRaw } from "vue";
import router from "@/router";
import { computed, ref } from "vue";

const toast = useToast()
const appImage = require("@/assets/logo/app.png")
const store = dataAuth.state.restorePassword
const storeEmail = dataAuth.state.forgotPassword.email
const isMobile = computed(() => window.innerWidth < 768);

// campo de senha
const showPassword = ref(false)
const inputType = computed(() => (showPassword.value ? 'text' : 'password'))
const togglePasswordVisibility = () => {
  showPassword.value = !showPassword.value
}

// confirmação de senha
const showPasswordConfirm = ref(false)
const inputTypeConfirm = computed(() => (showPasswordConfirm.value ? 'text' : 'password'))
const togglePasswordConfirmVisibility = () => {
  showPasswordConfirm.value = !showPasswordConfirm.value
}

const form = yup.object().shape({
  password: yup.string().required('É necessário informar uma nova senha').min(5, 'Deve ter no mínimo 5 caracteres'),
  confirm: yup.string().oneOf([yup.ref('password'), null], 'As senhas devem ser iguais').required('Por favor confirme a nova senha')
})

async function restorePassword() {
  if(! storeEmail) {
    toast('Email não informado, tente novamente', {
      toastClassName: 'toast-warning'
    })
    dataAuth.dispatch('updateComponentActive', markRaw(ForgotPassword))
  }

  storeApp.state.loading = true

  const validate = (await validateFormField(form, store))

  if (validate && validate.length > 0) {
    setFormFieldsError(validate)
    storeApp.state.loading = false
    return
  }

  await AuthService.restorePassword({...store, email: storeEmail})
    .then(response => {
      if(response.data.success) {
        
        toast(response.data.message, {
          toastClassName: 'toast-success'
        })

        AuthService.login({email: storeEmail, password: store.password})
          .then((res) => {
            storeApp.state.loading = false
            storeApp.dispatch('showMenuUserLogged')
            storeApp.dispatch('setUserAuthenticated', true)

            const userPermissions = [...res.data.user.permissions].map(
              (el) => el.key
            )

            localStorage.setItem('__permissions', userPermissions)
            localStorage.setItem('__access', res.data.access_token)
            localStorage.setItem('__uuid', res.data.user.id)
            localStorage.setItem('__user_name', res.data.user.name)
            localStorage.setItem('__company', res.data.user.companies.id)

            if (res.data.user.companies.photo !== null || res.data.user.companies.photo !== 'null') { 
              storeApp.dispatch('setCompanyLogo', res.data.user.companies.photo) 
            } else {
              const appDefault = require('@/assets/logo/app.png')
              storeApp.dispatch('setCompanyLogo', appDefault) 
            }

            storeApp.state.loading = false

            toast('Bem vindo', {
              toastClassName: 'toast-success'
            })

            storeApp.dispatch('restorePasswordResetFields')
            router.push('/')
          })
      }
    }).catch(err => {  
      storeApp.state.loading = false
      if (err.response.status && err.response.status === 400) {
        return toast('Código inválido!', {
          toastClassName: 'toast-error'
        })
      }
      if (err.response.status && err.response.status === 401) {
        return toast('Email inválido!', {
          toastClassName: 'toast-error'
        })
      }
    })
}

</script>

<style lang="scss" scoped>
input[type="text"],
input[type="password"],
input[type="email"] {
  background-color: #f6f6f6;
  border: none !important;
  color: #0d0d0d;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 5px;
  width: 85%;
  border: 2px solid #f6f6f6;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  -webkit-border-radius: 5px 5px 5px 5px;
  border-radius: 5px 5px 5px 5px;
  &:focus {
    background-color: #fff;
    border-bottom: 2px solid #5fbae9;
  }
  &::placeholder {
    color: rgba(0, 0, 0, 0.357);
  }
}
</style>
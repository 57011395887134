<template>
  <div id="app" :key="key">
    <Application />
  </div>
</template>

<script setup>
import Application from './main.vue';
import { computed, watch } from 'vue';
import { storeApp } from './store';
import { ref } from 'vue';

const showMenu = computed(() => storeApp.getters.getMenuState);
const key = ref(0)

watch(
  () => showMenu.value,
  (newVal) => {
    if (newVal) 
      key.value += 1
  },
  { immediate: true }
);

</script>

<style lang="scss">
#app {
  max-height: 100vh;
  font-family: Circular, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;

  ::-webkit-scrollbar {
    width: 4px;
    height: 1px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #888888ab;
    border-radius: 6px;
  }

  ::-webkit-scrollbar-track {
    background-color: #f1f1f1;
    border-radius: 6px;
  }
}
</style>

<template>
  <div class="chart overflow-x-auto">
    <Line
      class="w-full overflow-x-auto"
      id="chart"
      ref="chart"
      :data="chartData"
      :options="chartOptions"
      :height="250"
    />
  </div>
</template>

<script>
import { Line } from 'vue-chartjs'
import { Chart as ChartJS, Title, Tooltip, Legend, LineController, CategoryScale, LinearScale, PointElement, LineElement } from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, LineController, CategoryScale, LinearScale, PointElement, LineElement)

export default {
  name: 'ChartComponent',
  props: {
    labels: Array,
    dataMoviment: Array,
    dataCosts: Array,
    movimentColor: String,
    costColor: String
  },
  components: {
    Line
  },
  data () {
    return {
      chartData: {
        labels: this.labels,
        datasets: [
          {
            label: 'Entradas',
            backgroundColor: this.movimentColor,
            borderColor: this.movimentColor,
            borderWidth: 1,
            fill: false,
            data: this.dataMoviment
          },
          {
            label: 'Saídas',
            backgroundColor: this.costColor,
            borderColor: this.costColor,
            borderWidth: 1,
            fill: false,
            data: this.dataCosts
          }
        ]
      },
      chartOptions: {
        responsive: false,
        maintainAspectRatio: false,
        scales: {
          y: {
            beginAtZero: true,
            ticks: {
              precision: 0,
              callback: function (value) {
                return `R$ ${value.toFixed(0).replace('.', ',')}`
              }
            }
          }
        }
      }
    }
  },
  watch: {
    dataMoviment () {
      this.updateChartData()
    },
    dataCosts () {
      this.updateChartData()
    }
  },
  methods: {
    updateChartData () {
      this.chartData.datasets[0].data = this.dataMoviment
      this.chartData.datasets[1].data = this.dataCosts
      this.refreshChart()
    },
    refreshChart () {
      this.$nextTick(() => {
        const chart = this.$refs.chart
        if (chart && chart.chart) { chart.chart.update() }
      })
    }
  }
}
</script>

<style scoped>
.chart {
  margin: 1rem auto 1rem;
  overflow: hidden;
}
</style>

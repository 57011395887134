<template>
    <div class=" flex items-center justify-center">
      <div class="container max-w-screen-lg mx-auto">
        <div>
          <div class="bg-white rounded shadow-lg p-4 px-4 md:p-8 mb-6">
            <div class="grid gap-4 gap-y-2 text-sm grid-cols-1 lg:grid-cols-3">
              <div class="text-left hidden md:block">
                <p class="mb-4 font-medium text-lg text-gray-800">
                  {{ descriptionTitle }}
                </p>
              </div>

              <div class="lg:col-span-2">
                <div
                  class="grid gap-4 gap-y-2 text-sm grid-cols-1 md:grid-cols-5"
                >
                  <div v-show="! create" class="md:col-span-3">
                    <label class="text-left flex pl-1" for="name">
                      Senha anterior
                    </label>
                    <div
                      class="h-10 bg-gray-50 flex border border-gray-200 rounded items-center mt-1"
                    >
                      <input
                        v-model="store.password.old"
                        type="password"
                        name="name"
                        class="px-1 py-2 appearance-none outline-none text-gray-800 w-full bg-transparent"
                      />
                      <CleanInputButton
                        @click="store.password.old = undefined"
                      />
                    </div>

                    <span name="old" class="text-red-600 text-sm" />
                  </div>

                  <div :class="{ 'md:col-span-2': ! create, 'md:col-span-5': create }">
                    <label class="text-left flex pl-1" for="phone">
                      Nova senha
                    </label>
                    <div
                      class="h-10 bg-gray-50 flex border border-gray-200 rounded items-center mt-1"
                    >
                      <input
                        v-model="store.password.newPass"
                        type="password"
                        name="phone"
                        class="px-1 py-2 appearance-none outline-none text-gray-800 w-full bg-transparent"
                      />
                      <CleanInputButton
                        @click="store.password.newPass = undefined"
                      />
                    </div>

                    <span name="password.newPass" class="text-red-600 text-sm" />
                  </div>

                  <div class="md:col-span-5">
                    <label class="text-left flex pl-1" for="email">
                      Confirmar senha
                    </label>

                    <div
                      class="h-10 bg-gray-50 flex border border-gray-200 rounded items-center mt-1"
                    >
                      <input
                        v-model="store.password.confirm"
                        name="password"
                        type="password"
                        class="px-1 py-2 appearance-none outline-none text-gray-800 w-full bg-transparent"
                      />
                      <CleanInputButton
                        @click="store.password.confirm = undefined"
                      />
                    </div>

                    <span name="password.confirm" class="text-red-600 text-sm" />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="p-2 mt-1 mb-1" />

          <div>
            <slot />
          </div>

        </div>
      </div>
    </div>
  </template>

<script>
import CleanInputButton from '../components/CleanInputButton.vue'
import { dataCollab } from '../store'
import { useToast } from 'vue-toastification'

export default {
  name: 'FormVue',
  components: {
    CleanInputButton
  },
  props: {
    descriptionTitle: String,
    create: Boolean
  },
  data () {
    const store = dataCollab.state
    const toast = useToast()

    return {
      store,
      toast,
      selectedFileName: undefined,
      selectedFileUrl: undefined
    }
  }
}
</script>

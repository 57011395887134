import HomeView from './../HomeView.vue'

export const homeRoute = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {
      key: 'view_dashboard'
    }
  }
]

<template>
  {{ totalAmount }}
  <div class="overflow-x-auto py-2 sm:px-6 lg:px-2 rounded-md">
    <table class="w-full">
      <slot />
      <tbody>
        <tr v-for="(row, index) in paginatedItems" :key="index" class="hover:shadow-lg hover:-translate-y-1 hover:-translate-x-1 transition-all" :class="index % 2 === 0 ? 'bg-gray-100' : 'bg-white'">
          <td class="text-left whitespace-nowrap pr-3 text-sm text-gray-900 sm:pl-0 cursor-pointer min-w-[10px]">
            <div :class="{ 'text-center': center }">
              <span class="pl-2">
                {{ row.registered_by.length > 20
                  ? row.registered_by.slice(0, 20).concat("...")
                  : row.registered_by
                }}
              </span>
            </div>
          </td>
          <td class="text-left whitespace-nowrap pr-3 text-sm text-gray-900 sm:pl-0 cursor-pointer min-w-[10px]">
            <div :class="{ 'text-center': center }">
              <span class="pl-2">
                {{ row.cost_name.length > 20
                  ? row.cost_name.slice(0, 20).concat("...")
                  : row.cost_name
                }}
              </span>
            </div>
          </td>
          <td class="text-left whitespace-nowrap pr-3 text-sm text-gray-900 sm:pl-0 cursor-pointer min-w-[10px]">
            <div :class="{ 'text-center': center }">
              <span class="pl-2">
                {{ row.cost_amount }}
              </span>
            </div>
          </td>
          <td class="text-left whitespace-nowrap pr-3 text-sm text-gray-900 sm:pl-0 cursor-pointer min-w-[10px]">
            <div :class="{ 'text-center': center }">
              <span class="pl-2">
                {{ row.type }}
              </span>
            </div>
          </td>
          <td class="text-left whitespace-nowrap pr-3 text-sm text-gray-900 sm:pl-0 cursor-pointer min-w-[10px]">
            <div :class="{ 'text-center': center }">
              <span class="pl-2">
                {{ row.category.length > 20
                  ? row.category.slice(0, 20).concat("...")
                  : row.category
                }}
              </span>
            </div>
          </td>
          <td class="text-left whitespace-nowrap pr-3 text-sm text-gray-900 sm:pl-0 cursor-pointer min-w-[10px]">
            <div :class="{ 'text-center': center }">
              <span class="pl-2">
                {{ row.date ?? 'não informado' }}
              </span>
            </div>
          </td>

          <td class="text-left whitespace-nowrap pr-3 text-sm text-gray-900 sm:pl-0 cursor-pointer min-w-[10px]">
            <div :class="{ 'text-center': center }">
              <span class="pl-2">
                {{ row.payment }}
              </span>
            </div>
          </td>

          <td class="flex justify-center whitespace-nowrap py-1.5 pt-2 pr-2 text-sm font-medium text-gray-900 sm:pl-0">
            <ActionsButton
              :edit="false" :report="false"
              @delete="deleteCost(paginatedItems[index].id)"
            />
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <div v-if="this.filteredItems.length > 10">
    <section class="mt-4 flex justify-center">
      <Buttons
        class="mr-4"
        @click="prevPage"
        :disabled="currentPage === 1"
        :label="'Anterior'"
        :icon="'fa-solid fa-arrow-left'"
      />

      <Buttons
        class="ml-4"
        @click="nextPage"
        :disabled="currentPage === totalPages"
        :label="'Próximo'"
        :icon="'fa-solid fa-arrow-right'"
      />
    </section>

    <p>Página {{ currentPage }} de {{ totalPages }}</p>
  </div>
</template>

<script>
import ActionsButton from '@/components/Tables/ActionsButton.vue'
import CostsService from '../services/costs.service'
import Buttons from '@/components/Buttons/Create.vue'
import moment from 'moment'
import { useToast } from 'vue-toastification'
import { dataCosts } from '../store'
import { storeApp } from '@/store'

export default {
  props: {
    center: { Boolean, default: false },
    items: { type: Array, required: true },
    itemsPerPage: { type: Number, default: 10 }
  },
  components: {
    Buttons,
    ActionsButton
  },
  data () {
    return {
      currentPage: 1,
      toast: useToast(),
      storeApp: storeApp,
      store: dataCosts
    }
  },
  computed: {
    totalPages () {
      return Math.ceil(this.items.length / this.itemsPerPage)
    },
    filteredItems () {
      return this.items.filter(item => {
        const { user, name, amount, type, category, payment } = this.store.state.filters.fields
        const dateRange = this.store.state.filters.date
        
        const userMatch = user ? item.registered_by.toLowerCase().includes(user.toLowerCase()) : true
        const nameMatch = name ? item.cost_name.toLowerCase().includes(name.toLowerCase()) : true
        const amountMatch = amount ? item.cost_amount.toLowerCase().includes(amount.toLowerCase()) : true
        const typeMatch = type ? item.type.toLowerCase().includes(type.toLowerCase()) : true
        const categoryMatch = category ? item.category.toLowerCase().includes(category.toLowerCase()) : true
        const paymentMatch = payment ? item.payment.toLowerCase().includes(payment.toLowerCase()) : true

        let dateMatch = true
        if (dateRange?.length > 0) {
          let [startDate, endDate] = dateRange.map(date => moment(date, 'DD/MM/YY'))
          const itemDate = moment(item.date, 'DD/MM/YY');

          if (!endDate.isValid()) endDate = startDate.clone()

          dateMatch = itemDate.isBetween(startDate, endDate, 'day', '[]')
        }

        return userMatch && nameMatch && amountMatch && typeMatch && categoryMatch && paymentMatch && dateMatch
      })
    },
    filteredMoviments() {
      return this.store.state.moviments.filter(moviment => {
        const dateRange = this.store.state.filters.date;

        let dateMatch = true;
        if (dateRange?.length > 0) {
          let [startDate, endDate] = dateRange.map(date => moment(date, 'DD/MM/YY'));
          const movimentDate = moment(moviment.date, 'DD/MM/YY');

          if (!endDate.isValid()) endDate = startDate.clone();

          dateMatch = movimentDate.isBetween(startDate, endDate, 'day', '[]');
        }

        return dateMatch; // Apenas filtragem por data para movimentos
      });
    },
    paginatedItems () {
      const start = (this.currentPage - 1) * this.itemsPerPage
      const end = start + this.itemsPerPage
      return this.filteredItems.slice(start, end)
    },
    totalAmount () {
      this.calculateTotal(this.filteredItems)
      this.calculateTotal(this.filteredMoviments, 'total')
    }
  },
  methods: {
    parsePrice(price) {
      return parseFloat(price.replace('R$', '')
        .replace(/\./g, '')
        .replace(',', '.')
        .trim());
    },
    formatPrice(value) {
      return new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL',
        minimumFractionDigits: 2
      }).format(value);
    },
    calculateTotal(items, field = 'cost_amount') {
      const total = items.reduce((total, item) => {
        let amount = item[field];

        if (typeof amount !== 'number' || field == 'cost_amount') {
          amount = this.parsePrice(amount);
        }
     
        if (isNaN(amount)) {
          amount = 0;
        }

        return total + amount;
      }, 0).toFixed(2);

      const formattedTotal = this.formatPrice(total);

      if (field === 'total') {
        this.store.dispatch('updateTotalAmountMoviment', formattedTotal); // Atualiza total de movimentos
      } else {
        this.store.dispatch('updateTotalAmount', formattedTotal); // Atualiza total de itens
      }
    },
    prevPage () {
      if (this.currentPage > 1) {
        this.currentPage--
      }
    },
    nextPage () {
      if (this.currentPage < this.totalPages) {
        this.currentPage++
      }
    },
    deleteCost (id) {
      const permission = localStorage.getItem('__permissions')

      if (!permission.split(',').includes('view_costs')) {
        return this.toast('Você não tem permissão para remover custos', {
          toastClassName: 'toast-info'
        })
      }
      storeApp.state.loading = true
      const confirm = window.confirm('Deseja realmente remover esse custo ?')
      if (confirm) {
        CostsService.removeCosts(id).then(res => {
          if (res.data.success) {
            this.toast(res.data.success, {
              toastClassName: 'toast-success'
            })
          }

          this.$emit('reload')
        }).catch(err => {
          this.toast(err.response.data.error, { toastClassName: 'toast-error' })
        })
      }
    }
  }
}
</script>

<style scoped>
button {
  margin: 5px;
}
</style>

<template>
  <div 
    class="border w-full max-w-[100%] overflow-x-auto border-gray-300 p-1 mt-1 rounded-2xl max-h-[90vh]"
    :class="[ minWith ? 'min-w-min' : '' ]"
  >
    <div class="md:w-full overflow-x-auto">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'ContainerVue',
  props: {
    minWith: { Boolean, default: false}
  }
}
</script>

<template>
  <div>
    <PageTitleVue> Cadastrar de funcionário </PageTitleVue>

    <ContainerVue>
      <Form id="form-fields" :descriptionTitle="'Dados pessoais'">
        <Password :create="true" :descriptionTitle="'Senha de acesso'" />
        <PermissionsForm :create="true" />
        <div class="w-full flex justify-center mt-4">
          <CreateButton
            @click="newCollaborator"
            :icon="'fa-solid fa-user-plus'"
            :label="'Adicionar'"
          />
        </div>
      </Form>
    </ContainerVue>
    <FloatBackPage />
  </div>
</template>

<script setup>
import FloatBackPage from '@/components/Buttons/FloatBackPage.vue'
import PageTitleVue from '@/components/Titles/PageTitle.vue'
import CreateButton from '@/components/Buttons/Create.vue'
import CollabService from '../services/collab.service'
import ContainerVue from '@/components/Container.vue'
import PermissionsForm from './PermissionsForm.vue'
import Password from './PasswordForm.vue'
import Form from '../layouts/Form.vue'
import * as yup from '@/validator'
import { dataCollab } from '../store'
import { storeApp } from '@/store'
import { setFormFieldsError, validateFormField } from '@/helpers/formFieldsHandler'
import { onBeforeMount } from 'vue'
import { useToast } from 'vue-toastification'

const appStore = storeApp
const store = dataCollab
const toast = useToast()

const form = yup.object().shape({
  name: yup.string().required().label('Nome').min(3).max(100),
  phone: yup.string().nullable().label('Celular').max(20),
  email: yup.string().required().label('Email').max(100),
  role: yup.string().required().label('Função').max(50),
  password: yup.object().shape({
    newPass: yup.string().required('É necessário informar a nova senha').min(5, 'Deve ter no mínimo 5 caracteres'),
    confirm: yup.string().oneOf([yup.ref('newPass'), null], 'As senhas devem ser iguais').required('Por favor confirme a nova senha')
  })
})

onBeforeMount(async () => {
  appStore.state.loading = true
  store.commit('reset')
  await permissions()
  appStore.state.loading = false
})

async function permissions () {
  await CollabService.getPermissions()
    .then(res => {
      store.state.permissions = res.data
    }).catch(err => {
      toast('Algo deu arrado ao buscar dados do funcionário', {
        toastClassName: 'toast-error'
      })
    })
}

async function newCollaborator () {
  appStore.state.loading = true

  // validação dos campos de formulário
  const validate = (await validateFormField(form, store.state))
  if (validate && validate.length > 0) {
    setFormFieldsError(validate)
    appStore.state.loading = false
    return
  }

  const data = Object.assign({}, store.state)

  delete data.userPermissions
  delete data.permissions
  delete data.haircuts
  delete data.password.confirm
  delete data.password.old
  data.password = data.password.newPass

  await CollabService.register(data)
    .then(res => {
      appStore.state.loading = false
      if (res.data.success) {
        toast(res.data.success, {
          toastClassName: 'toast-success'
        })
      }
    })
    .catch(err => {
      appStore.state.loading = false

      if (err.response.status && err.response.status === 400) {
        return toast(err.response.data.message, {
          toastClassName: 'toast-error'
        })
      }

      toast(err.response.data.error, {
        toastClassName: 'toast-error'
      })
    })
}

</script>

<style>

</style>

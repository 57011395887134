import { createStore } from 'vuex'

export const dataClient = createStore({
  state: () => {
    return {
      name: undefined,
      cellphone: undefined,
      instagram: undefined,
      facebook: undefined
    }
  },
  mutations: {
    reset (state) {
      state.name = undefined
      state.cellphone = undefined
      state.instagram = undefined
      state.facebook = undefined
    },
    updateClientFields (state, payload) {
      Object.assign(state, payload)
    }
  }
})

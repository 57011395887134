import { createStore } from 'vuex'

export const dataCosts = createStore({
  state: () => {
    return {
      items: [],
      moviments: [], // guarda o array de movimentos do back
      amounts: {
        moviment: '0,00',
        costs: '0,00'
      },
      filters: {
        date: [],
        fields: {
          registered_by: '',
          cost_name: '',
          cost_amount: '',
          type: '',
          category: '',
          payment: '',
        },
        applyeds: []
      },
      form: {
        user: '',
        name: '', // descrição
        amount: '', // valor
        type: '',
        category: '',
        date: new Date(),
        payment: '',
        observation: ''
      }
    }
  },
  getters: {
    getMovimentTotal: state => state.amounts.moviment,
    getTotalCosts: state => state.amounts.costs,
    getApplyedFilters: state => state.filters.applyeds,
  },
  mutations: {
    reset (state) {
      state.items = []
      state.filters.applyeds = [] 
      state.amounts.moviment = '0,00'
      state.amounts.costs = '0,00'
      state.filters.date = []
      state.filters.fields = {}
      state.form = {}
    },
    setUserFilter (state, value) {
      state.filters.fields.user = value
    },
    setNameFilter (state, value) {
      state.filters.fields.name = value
    },
    setAmountFilter (state, value) {
      state.filters.fields.amount = value
    },
    setTypeFilter (state, value) {
      state.filters.fields.type = value
    },
    setCategoryFilter (state, value) {
      state.filters.fields.category = value
    },
    setPaymentFilter (state, value) {
      state.filters.fields.payment = value
    },
    setTotalCosts (state, value) {
      state.amounts.costs = value
    },
    setTotalMoviment (state, value) {
      state.amounts.moviment = value
    },

    // define os campos ativos de filtro
    setFilteredApplyed (state, { data, index }) {
      const findIndex = state.filters.applyeds.find(
        (el) => el.key === index
      )

      findIndex
        ? findIndex.value = data
        : state.filters.applyeds.push(
          {
            key: index,
            value: data
          }
        )
    },
    
    // limpa o fitlro informado
    removeItemByKey (state, key) {
      const item = state.filters.applyeds.findIndex(
        (el) => el.key === key
      )

      if (item !== -1) {
        state.filters.applyeds.splice(item, 1)
      }

      if (key === 'Registrado por') state.filters.fields.user = ''
      else if (key === 'Descrição') state.filters.fields.name = ''
      else if (key === 'Valor') state.filters.fields.amount = ''
      else if (key === 'Tipo') state.filters.fields.type = ''
      else if (key === 'Categoria') state.filters.fields.category = ''
      else if (key === 'Pago via') state.filters.fields.payment = ''
    }
  },
  actions: {
    updateFilterUser ({ commit }, data) {
      commit('setUserFilter', data)
      commit('setFilteredApplyed', { data, index: 'Registrado por' })
    },
    updateFilterName ({ commit }, data) {
      commit('setNameFilter', data)
      commit('setFilteredApplyed', { data, index: 'Descrição' })
      console.log(this.state.filters.applyeds);
    },
    updateFilterAmount ({ commit }, data) {
      commit('setAmountFilter', data)
      commit('setFilteredApplyed', { data, index: 'Valor' })
    },
    updateFilterType ({ commit }, data) {
      commit('setTypeFilter', data)
      commit('setFilteredApplyed', { data, index: 'Tipo' })
    },
    updateFilterCategory ({ commit }, data) {
      commit('setCategoryFilter', data)
      commit('setFilteredApplyed', { data, index: 'Categoria' })
    },
    updateFilterPayment ({ commit }, data) {
      commit('setPaymentFilter', data)
      commit('setFilteredApplyed', { data, index: 'Pago via' })
    },
    updateTotalAmount ({ commit }, value) {
      commit('setTotalCosts', value)
    },
    updateTotalAmountMoviment ({ commit }, value) {
      commit('setTotalMoviment', value)
    },
    removeSelectedFilter ({ commit }, key) {
      commit('removeItemByKey', key)
    },
  }
})

<template>
  <div class="p-6 flex items-center justify-center">
    <div class="container max-w-screen-lg mx-auto">
      <div>
        <div class="bg-white rounded shadow-lg p-4 px-4 md:p-8 mb-6">
          <div class="grid gap-4 gap-y-2 text-sm grid-cols-1 lg:grid-cols-3">
            <div class="text-left hidden md:block">
              <p class="mb-4 font-medium text-lg text-gray-800">
                {{ descriptionTitle }}
              </p>
              <p class="text-gray-500">
                Campos com <span class="text-red-500">*</span> são obrigatórios
              </p>
            </div>

            <div id="form-fields" class="lg:col-span-2">
              <div
                class="grid gap-4 gap-y-2 text-sm grid-cols-1 md:grid-cols-5"
              >
                <div class="md:col-span-3">
                  <label class="text-left flex pl-1" for="name">
                    Produto / Serviço <a class="text-red-600 ml-1">*</a>
                  </label>
                  <div
                    class="h-10 bg-gray-50 flex border border-gray-200 rounded items-center mt-1"
                  >
                    <input
                      v-model="store.name"
                      type="text"
                      name="name"
                      class="px-1 py-2 appearance-none outline-none text-gray-800 w-full bg-transparent"
                    />
                    <CleanInputButton
                      @click="store.name = null"
                    />
                  </div>

                  <span name="name" class="text-red-600 text-sm" />
                </div>

                <div class="md:col-span-2">
                  <label class="text-left flex pl-1" for="cellphone">
                    Estoque
                  </label>
                  <div
                    class="h-10 bg-gray-50 flex border border-gray-200 rounded items-center mt-1"
                  >
                    <input
                      v-model="store.amount"
                      type="number"
                      name="amount"
                      class="px-1 py-2 appearance-none outline-none text-gray-800 w-full bg-transparent"
                    />
                    <CleanInputButton
                      @click="store.amount = null"
                    />
                  </div>
                </div>

                <div class="md:col-span-3">
                  <label class="text-left flex pl-1" for="instagram">
                    Valor $ <a class="text-red-600 ml-1">*</a>
                  </label>

                  <div
                    class="h-10 bg-gray-50 flex border border-gray-200 rounded items-center mt-1"
                  >
                    <input
                      v-model="store.price"
                      @input="formatCurrency"
                      name="price"
                      placeholder="R$ 0,00"
                      class="px-1 py-2 appearance-none outline-none text-gray-800 w-full bg-transparent"
                    />
                    <CleanInputButton
                      @click="store.price = null"
                    />
                  </div>

                  <span name="price" class="text-red-600 text-sm" />
                </div>

                <div class="md:col-span-2">
                  <label class="text-left flex pl-1" for="facebook">
                    Observação
                  </label>
                  <div
                    class="h-10 bg-gray-50 flex border border-gray-200 rounded items-center mt-1"
                  >
                    <input
                      v-model="store.description"
                      name="description"
                      class="px-1 appearance-none outline-none text-gray-800 w-full bg-transparent"
                    />
                    <CleanInputButton
                      @click="store.description = null"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="flex justify-center mt-8">
            <slot />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import CleanInputButton from '../components/CleanInputButton.vue'
import { dataProduct } from '../store/'
import { defineProps, ref } from 'vue'

const store = dataProduct.state
const rawPrice = ref('')
const props = defineProps({
  descriptionTitle: String
})

const formatCurrency = (event) => {
  const onlyDigits = event.target.value.replace(/\D/g, '')

  const formatted = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL'
  }).format(onlyDigits / 100)

  store.price = formatted
  rawPrice.value = onlyDigits / 100
}

</script>

<template>
    <div class="p-6 flex items-center justify-center">
      <div class="container max-w-screen-lg mx-auto">
        <div>
          <div class="bg-white rounded shadow-lg p-4 px-4 md:p-8 mb-6">
            <div class="grid gap-4 gap-y-2 text-sm grid-cols-1 lg:grid-cols-3">
              <div class="text-left hidden md:block">
                <p class="mb-4 font-medium text-lg text-gray-800">
                  {{ descriptionTitle }}
                </p>
                <p class="text-gray-500">
                  Campos com <span class="text-red-500">*</span> são obrigatórios
                </p>
              </div>

              <div id="form-fields" class="lg:col-span-2">
                <div
                  class="grid gap-4 gap-y-2 text-sm grid-cols-1 md:grid-cols-5"
                >
                  <div class="md:col-span-3">
                    <label class="text-left flex pl-1" for="name">
                      Nome <a class="text-red-600 ml-1">*</a>
                    </label>
                    <div
                      class="h-10 bg-gray-50 flex border border-gray-200 rounded items-center mt-1"
                    >
                      <input
                        v-model="store.name"
                        type="text"
                        name="name"
                        class="px-1 py-2 appearance-none outline-none text-gray-800 w-full bg-transparent"
                        placeholder="email@domain.com"
                      />
                      <CleanInputButton
                        @click="store.name = null"
                      />
                    </div>

                    <span name="name" class="text-red-600 text-sm" />
                  </div>

                  <div class="md:col-span-2">
                    <label class="text-left flex pl-1" for="cellphone">
                      Celular
                    </label>
                    <div
                      class="h-10 bg-gray-50 flex border border-gray-200 rounded items-center mt-1"
                    >
                      <input
                        v-model="store.cellphone"
                        type="text"
                        v-mask="'(##) # ####-####'"
                        name="cellphone"
                        class="px-1 py-2 appearance-none outline-none text-gray-800 w-full bg-transparent"
                      />
                      <CleanInputButton
                        @click="store.cellphone = null"
                      />
                    </div>

                    <span name="store.cellphone" class="text-red-600 text-sm" />
                  </div>

                  <div class="md:col-span-3">
                    <label class="text-left flex pl-1" for="instagram">
                      Instagram
                    </label>

                    <div
                      class="h-10 bg-gray-50 flex border border-gray-200 rounded items-center mt-1"
                    >
                      <input
                        v-model="store.instagram"
                        name="function"
                        placeholder="@perfil"
                        class="px-1 py-2 appearance-none outline-none text-gray-800 w-full bg-transparent"
                      />
                      <CleanInputButton
                        @click="store.instagram = null"
                      />
                    </div>

                    <span name="store.instagram" class="text-red-600 text-sm" />
                  </div>

                  <div class="md:col-span-2">
                    <label class="text-left flex pl-1" for="facebook">
                      Facebook
                    </label>
                    <div
                      class="h-10 bg-gray-50 flex border border-gray-200 rounded items-center mt-1"
                    >
                      <input
                        v-model="store.facebook"
                        name="facebook"
                        placeholder="Usuario do facebook"
                        class="px-1 appearance-none outline-none text-gray-800 w-full bg-transparent"
                      />
                      <CleanInputButton
                        @click="store.facebook = null"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="flex justify-center mt-8">
              <slot />
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>

<script>
import CleanInputButton from '../components/CleanInputButton.vue'
import { dataClient } from '../store/'
import { useToast } from 'vue-toastification'

export default {
  name: 'FormVue',
  components: {
    CleanInputButton
  },
  props: {
    descriptionTitle: String
  },
  data () {
    const store = dataClient.state
    const toast = useToast()

    return {
      store,
      toast,
      selectedFileName: undefined,
      selectedFileUrl: undefined
    }
  }
}
</script>

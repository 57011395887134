import { createStore } from 'vuex'

export const dataProduct = createStore({
  state: () => {
    return {
      name: undefined,
      amount: undefined,
      description: undefined,
      price: undefined
    }
  },
  mutations: {
    reset (state) {
      state.name = undefined
      state.amount = undefined
      state.description = undefined
      state.price = undefined
    },
    updateProductFields (state, payload) {
      Object.assign(state, payload)
    }
  }
})

<template>
  <div>
    <PageTitleVue> Atualização de cliente </PageTitleVue>

    <ContainerVue>
      <Form :descriptionTitle="'Editar os dados de ' + store?.state?.name || '' ">
        <CreateButton
          @click="updateClient"
          :icon="'fa-solid fa-user-plus'"
          :label="'Atualizar'"
        />
      </Form>
    </ContainerVue>
    <FloatBackPage />
  </div>
</template>

<script setup>
import CreateButton from '@/components/Buttons/Create.vue'
import FloatBackPage from '@/components/Buttons/FloatBackPage.vue'
import ContainerVue from '@/components/Container.vue'
import PageTitleVue from '@/components/Titles/PageTitle.vue'
import Form from '../layouts/Form.vue'
import * as yup from '@/validator'
import { dataClient } from '../store'
import { storeApp } from '@/store'
import { setFormFieldsError, validateFormField } from '@/helpers/formFieldsHandler'
import { onBeforeMount, onMounted } from 'vue'
import ClientService from '../services/clients.service'
import { useToast } from 'vue-toastification'
import { useRoute } from 'vue-router'

const client_id = useRoute().params.id
const appStore = storeApp
const store = dataClient
const toast = useToast()

const form = yup.object().shape({
  name: yup.string().required().label('Nome').min(3).max(100),
  cellphone: yup.string().label('Celular').max(20),
  instagram: yup.string().nullable().label('Instagram').max(100),
  facebook: yup.string().nullable().label('Facebook').max(100)
})

onBeforeMount(async () => {
  appStore.state.loading = true
  await getClient()
  appStore.state.loading = false
})

async function getClient () {
  store.commit('reset')
  await ClientService.getClientById(client_id)
    .then(res => {
      store.commit('updateClientFields', res.data)
    }).catch(err => {
      toast('Algo deu arrado ao buscar cliente', {
        toastClassName: 'toast-error'
      })
    })
}

async function updateClient () {
  appStore.state.loading = true

  const validate = (
    await validateFormField(form, store.state)
  )

  if (validate && validate.length > 0) {
    console.log(validate)
    setFormFieldsError(validate)
    appStore.state.loading = false
    return
  }

  await ClientService.editClient(store.state.id, store.state)
    .then(res => {
      appStore.state.loading = false
      if (res.data.success) {
        toast(res.data.success, {
          toastClassName: 'toast-success'
        })
      }
    })
    .catch(err => {
      appStore.state.loading = false
      toast('Algo deu errado na criação do cliente', {
        toastClassName: 'toast-error'
      })
    })
}

</script>

<style>

</style>

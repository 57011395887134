<template>
  <div class="loading-container" v-show="this.$store.state.setMenuCoverage"></div>
</template>

<script>
export default { }
</script>

<style>
.loading-container {
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.566);
}
</style>

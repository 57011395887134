import MovimentView from '../MovimentView.vue'

export const movimentRoutes = [
  {
    path: '/moviment',
    name: 'moviment',
    component: MovimentView,
    beforeEnter: (to, from, next) => {
      if (!localStorage.__access) next({ name: 'login' })
      next()
    },
    meta: {
      key: 'view_haircuts'
    }
  }
]

import { createStore } from 'vuex'

export const dataCollab = createStore({
  state: () => {
    return {
      name: undefined,
      phone: undefined,
      email: undefined,
      role: undefined,
      haircuts: undefined,
      permissions: undefined,
      userPermissions: undefined,
      updatePermissions: undefined,
      password: {
        old: undefined,
        newPass: undefined,
        confirm: undefined
      }
    }
  },
  mutations: {
    reset (state) {
      state.name = undefined
      state.phone = undefined
      state.email = undefined
      state.role = undefined
      state.haircuts = undefined
      state.permissions = undefined
      state.userPermissions = undefined
      state.updatePermissions = undefined
      state.password.old = undefined
      state.password.newPass = undefined
      state.password.confirm = undefined
    },
    updateCollabFields (state, payload) {
      Object.assign(state, payload)
    }
  }
})

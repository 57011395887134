<template>
  <div class="z-10">
    <PageTitleVue> Inicio </PageTitleVue>

    <!-- cards -->
    <div 
      class="w-full mb-1"
      :class="[
        isMobile ? 'block font-medium' : 'flex justify-center font-semibold'
      ]"
    >
      <TotalAmount
        :label="'Total de entrada'"
        :value="formatPrice(totalAmount)"
        :totalAmount="true"
      />
      <TotalAmount
        :label="'Total de saida'"
        :value="formatPrice(totalCosts)"
        :totalCosts="true"
      />
      <TotalAmount
        :label="'Serviços realizados'"
        :value="totalServices"
        :totalServices="true"
      />
    </div>

    <!-- chart -->
    <div 
      class="p-1 rounded-2xl"
      v-if="barbersData.length"
      :class="[
        isMobile ? 'block' : 'flex'
      ]"
    >
      <TheLineChart
        :key="barberPerPage()"
        :labels="months"
        :data="barberPerPage()"
      />

      <div class="p-5 border rounded-2xl text-left min-w-[25%]" :class="[! isMobile ? 'w-1/4 mt-3' : 'w-full']">
        <!-- title -->
        <div class="w-full text-center -mt-3 mb-3 text-xl font-semibold">
          <h1>
            Equipe
          </h1>
        </div>
        
        <!-- barbers -->
        <div class="max-h-[50vh] overflow-y-auto">
          <div v-for="(item, index) in barberPerPage()" :key="index">
            <div 
              class="flex font-medium p-2 rounded-2xl border-b-[#ccc]">
              <UserCircleIcon 
                class="my-auto w-10 h-10 mr-5 rounded-full"
                :class="[
                  `text-[${item.backgroundColor}]`
                ]"
              />
              <span>
                {{ 
                  isMobile ? item.name : item.name.slice(0, 10).concat("...")
                }} <br>
                <small>
                  serviços: {{ item.services }}
                </small>
              </span>
            </div> <hr>
          </div>
        </div>

        <!-- footer -->
        <div v-if="barbersData.length >= 5" class="w-full text-center">
          <div class="w-full mt-6 mb-1 font-semibold flex justify-center">
            Mostrando 
            <input 
              class="border border-[#ccc] rounded min-w-[8.333333%] w-1/12 text-center mx-1" 
              type="text"
              v-model="limitBarbers"
            > 
            colaboradores
          </div>
          <small>
            total de {{ barbersData.length }}
          </small>
        </div>
      </div>
    </div>

    <div v-else>
      <NoData :width="300" :height="300" :label="'Ainda não há movimentações'" />
    </div>

    <FloatCreateVue
      :link="'releases'"
      :menuActive="'Serviços'"
      :icon="'fas fa-plus'"
    />
  </div>
</template>

<script setup>
import FloatCreateVue from '@/components/Buttons/FloatCreate.vue'
import PageTitleVue from '@/components/Titles/PageTitle.vue'
import TheLineChart from './components/TheLineChart.vue'
import NoData from '@/components/Lotties/NoDataAnim.vue'
import TotalAmount from './components/TotalAmount.vue'
import HomeService from './services/home.service'
import { onBeforeMount, ref, computed, watch } from 'vue'
import { UserCircleIcon } from '@heroicons/vue/outline'
import { useToast } from 'vue-toastification'
import { storeApp } from '@/store'
import { dataHome } from './store'

const isMobile = computed(() => window.innerWidth < 768);
const appStore = storeApp
const toast = useToast()
const store = dataHome
// chart
const limitBarbers = ref(3)
const barbersData = ref([])
const months = ref([
  'Jan',
  'Fev',
  'Mar',
  'Abr',
  'Mai',
  'Jun',
  'Jul',
  'Ago',
  'Set',
  'Out',
  'Nov',
  'Dez'
])
// cards
const totalServices = ref('0');
const totalAmount = ref('0');
const totalCosts = ref('0');

const barberPerPage = () => {
  const limit = limitBarbers.value && limitBarbers.value > 0 ? limitBarbers.value : barbersData.value.length;

  return barbersData.value.slice(0, limit);
}

const generateRandomColor = () => {
  const letters = '0123456789ABCDEF';
  let color = '#';
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

watch(limitBarbers, () => {
  barberPerPage();
});

onBeforeMount(async () => {
  appStore.state.loading = true
  await getDataChart()
  appStore.state.loading = false
})

async function getDataChart () {
  await HomeService.getDataChartTable()
    .then(res => {
      
      if (res.data) {
        const barbers = res.data.barbers;

        const formattedBarbersData = [];
        const predefinedColors = [
          '#009285',
          '#B45A5A',
          '#B4955A',
          '#5a5ab4',
          '#a45ab4'
        ]

        for (const [index, [barberName, barberData]] of Object.entries(barbers).entries()) {
          // Usa uma cor pré-definida para os primeiros 5, depois gera cores aleatórias
          const color = index < predefinedColors.length ? predefinedColors[index] : generateRandomColor();
          
          formattedBarbersData.push({
            label: barberName.slice(0, 10).concat("..."),
            name: barberName,
            services: barberData.totalService,
            backgroundColor: color,
            borderColor: color,
            borderWidth: 1,
            fill: false,
            data: Object.values(barberData.servicesByMonth).map(value => String(value))  // Converte valores mensais para string
          });
        }

        barbersData.value = formattedBarbersData;
        totalServices.value = res.data.servicesPerformed;
        totalAmount.value = res.data.totalAmount;
        totalCosts.value = res.data.totalCosts;
      }
    }).catch(err => {
      toast('Algo deu errado ao buscar dados do gráfico', {
        toastClassName: 'toast-error'
      })
    })
}


function formatPrice(value) {
  return new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
    minimumFractionDigits: 2
  }).format(value);
}

</script>

import { http } from '@/http'

const PATH = 'app/'

class MovimentService {
  async getMoviments () {
    return http.get(PATH.concat('moviment'))
  }

  async deleteSale (id) {
    return http.delete(PATH.concat(id).concat('/remove/').concat(localStorage.getItem('__uuid')))
  }
}

export default new MovimentService()

<template>
  <div 
    class="chart w-full overflow-x-auto"
  >
    <Line
      class="overflow-x-auto w-full"
      id="chart"
      ref="chart"
      :data="chartData"
      :options="chartOptions"
      :height="450"
    />
  </div>
</template>

<script>
import { Line } from 'vue-chartjs'
import { Chart as ChartJS, Title, Tooltip, Legend, LineController, CategoryScale, LinearScale, PointElement, LineElement } from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, LineController, CategoryScale, LinearScale, PointElement, LineElement)

export default {
  name: 'ChartComponent',
  props: {
    labels: Array,
    data: Array,
  },
  components: {
    Line
  },
  data () {
    return {
      chartData: {
        labels: this.labels,
        datasets: this.data.map(dataset => ({
          ...dataset,
          tension: 0.2  // Suavização da linha (0 = linha reta, 1 = curva completa)
        }))
      },
      chartOptions: {
        responsive: false,
        maintainAspectRatio: false,
        scales: {
          y: {
            beginAtZero: true,
            ticks: {
              precision: 0
            }
          }
        }
      }
    }
  },
  watch: {
    data () {
      this.updateChartData()
    }
  },
  methods: {
    updateChartData () {
      this.chartData.datasets = this.data
      this.refreshChart()
    },
    refreshChart () {
      this.$nextTick(() => {
        const chart = this.$refs.chart
        if (chart && chart.chart) { chart.chart.update() }
      })
    }
  }
}
</script>

<style scoped>
.chart {
  margin: 1rem auto 1rem;
  overflow: hidden;
}
</style>

<template>
  <div class="p-6 flex items-center justify-center">
    <div class="container max-w-screen-lg mx-auto">
      <div>
        <div class="bg-white rounded shadow-lg p-4 px-4 md:p-8 mb-6">
          <div class="grid gap-4 gap-y-2 text-sm grid-cols-1 lg:grid-cols-3">
            <div class="text-left hidden md:block">
              <p class="mb-4 font-medium text-lg text-gray-800">
                {{ descriptionTitle }}
              </p>
              <p class="text-gray-500">
                Campos com <span class="text-red-500">*</span> são obrigatórios
              </p>
            </div>

            <div class="lg:col-span-2">
              <div
                class="grid gap-4 gap-y-2 text-sm grid-cols-1 md:grid-cols-5"
              >
                <div class="md:col-span-3">
                  <label class="text-left flex pl-1" for="name">
                    Nome <a class="text-red-600 ml-1">*</a>
                  </label>
                  <div
                    class="h-10 bg-gray-50 flex border border-gray-200 rounded items-center mt-1"
                  >
                    <input
                      v-model="store.name"
                      type="text"
                      name="name"
                      class="px-1 py-2 appearance-none outline-none text-gray-800 w-full bg-transparent"
                      placeholder="email@domain.com"
                    />
                    <CleanInputButton
                      @click="store.name = null"
                    />
                  </div>

                  <span name="name" class="text-red-600 text-sm" />
                </div>

                <div class="md:col-span-2">
                  <label class="text-left flex pl-1" for="phone">
                    Celular
                  </label>
                  <div
                    class="h-10 bg-gray-50 flex border border-gray-200 rounded items-center mt-1"
                  >
                    <input
                      v-model="store.phone"
                      type="text"
                      v-mask="'(##) # ####-####'"
                      placeholder="(##) # ####-####"
                      name="phone"
                      class="px-1 py-2 appearance-none outline-none text-gray-800 w-full bg-transparent"
                    />
                    <CleanInputButton
                      @click="store.phone = null"
                    />
                  </div>
                </div>

                <div class="md:col-span-3">
                  <label class="text-left flex pl-1" for="email">
                    Email <a class="text-red-600 ml-1">*</a>
                  </label>

                  <div
                    class="h-10 bg-gray-50 flex border border-gray-200 rounded items-center mt-1"
                  >
                    <input
                      v-model="store.email"
                      name="email"
                      type="email"
                      placeholder="exemplo@exemplo.com"
                      class="px-1 py-2 appearance-none outline-none text-gray-800 w-full bg-transparent"
                    />
                    <CleanInputButton
                      @click="store.email = null"
                    />
                  </div>

                  <span name="email" class="text-red-600 text-sm" />
                </div>

                <div class="md:col-span-2">
                  <label class="text-left flex pl-1" for="facebook">
                    Função <a class="text-red-600 ml-1">*</a>
                  </label>
                  <div
                    class="h-10 bg-gray-50 flex border border-gray-200 rounded items-center mt-1"
                  >
                    <input
                      v-model="store.role"
                      name="role"
                      placeholder="Auxiliar"
                      class="px-1 appearance-none outline-none text-gray-800 w-full bg-transparent"
                    />
                    <CleanInputButton
                      @click="store.role = null"
                    />
                  </div>

                  <span name="role" class="text-red-600 text-sm" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="p-2 mt-1 mb-1" />

        <div>
          <slot />
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import CleanInputButton from '../components/CleanInputButton.vue'
import { dataCollab } from '../store/'
import { useToast } from 'vue-toastification'

export default {
  name: 'FormVue',
  components: {
    CleanInputButton
  },
  props: {
    descriptionTitle: String
  },
  data () {
    const store = dataCollab.state
    const toast = useToast()

    return {
      store,
      toast,
      selectedFileName: undefined,
      selectedFileUrl: undefined
    }
  }
}
</script>

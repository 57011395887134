import { http } from '@/http'

const PATH = 'app/products/'

class ProductService {
  async getAllProducts () {
    return http.get(PATH.concat('all'))
  }

  async register (data) {
    return http.post(PATH.concat('new'), data, {
      headers: {
        __user: localStorage.getItem('__uuid')
      }
    })
  }

  async getProductById (id) {
    return http.get(PATH.concat(id).concat('/iten'))
  }

  async editProduct (id, data) {
    return http.patch(PATH.concat(id).concat('/edit'), data, {
      headers: {
        __user: localStorage.getItem('__uuid')
      }
    })
  }

  async deleteProduct (id) {
    return http.patch(PATH.concat(id).concat('/disable'), {}, {
      headers: {
        __user: localStorage.getItem('__uuid')
      }
    })
  }
}

export default new ProductService()

import { createStore } from 'vuex'

export const storeApp = createStore({
  state: () => {
    return {
      accessToken: localStorage,
      loading: false,
      showMenu: false,
      permissions: [],
      menuOpen: false,
      activeSubMenu: null,
      selectedMenu: "Inicio",
      setMenuCoverage: false,
      companyLogo: null,
      userAuthenticated: false,
    }
  },
  getters: {
    getMenuState: state => state.showMenu,
    getCompanyLogo: state => state.companyLogo,
    getUserAuthenticate: state => state.userAuthenticated
  },
  mutations: {
    setImageMenu (state, value) {
      let img = localStorage.setItem('__company_logo', value)
      state.companyLogo = img
    },
    expandedMenu (state, value) {
      state.menuOpen = value
      state.setMenuCoverage = value
    },
    setActiveSubMenu(state, menu) {
      state.activeSubMenu = menu;
    },
    clearActiveSubMenu(state) {
      state.activeSubMenu = null;
    },
    showActivateMenu(state, value) {
      state.selectedMenu = value;
    },
    showMenuIfLogged(state, value) {
      state.showMenu = value
    },
    authenticatedUser(state, value) {
      state.userAuthenticated = value
    }
  },
  actions: {
    setCompanyLogo ({ commit }, value) {
      commit('setImageMenu', value)
    },
    setMenuExpanded({ commit }, value) {
      commit('expandedMenu', value)
    },
    setActivatedMenu({ commit }, value) {
      commit('showActivateMenu', value)
    },
    showMenuUserLogged({commit}) {
      commit('showMenuIfLogged', true)
    },
    hiddenMenuUserLoggedOut({commit}) {
      commit('showMenuIfLogged', false)
    },
    setUserAuthenticated({commit}, value) {
      commit('authenticatedUser', value)
    },
  }
})

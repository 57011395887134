import CompanyView from '../CompanyView.vue'
import EditCompany from './../layout/UpdateCompany.vue'

export const companyRoute = [
  {
    path: '/company',
    name: 'company',
    alias: '/me-company',
    component: CompanyView,
    beforeEnter: (to, from, next) => {
      if (!localStorage.__access) next({ name: 'login' })
      next()
    },
    meta: {
      key: 'view_company'
    }
  },
  {
    path: '/company/:id/edit',
    name: 'edit-company',
    alias: '/edit-company',
    component: EditCompany,
    beforeEnter: (to, from, next) => {
      if (!localStorage.__access) next({ name: 'login' })
      next()
    },
    meta: {
      key: 'view_company'
    }
  }
]

<template>
  <div>
    <div class="fadeIn first mb-5">
      <img 
        :src="appImage" 
        alt="BarbApp" 
        class="w-32 h-28 mx-auto rounded-full" 
      />
    </div>
    <div id="form-fields" class="lg:col-span-2">
      <!-- user name -->
      <input 
        type="text" 
        class="fadeIn second" 
        placeholder="Nome de Usuario" 
        name="user_name"
        v-model="store.user_name"
      />
      <br>
      <span name="user_name" class="text-red-600 text-sm"></span>

      <!-- email -->
      <input 
        type="email" 
        class="shadow-sm fadeIn second" 
        placeholder="Email de acesso" 
        autocomplete="on"
        name="email"
        v-model="store.email"
      />
      <br>
      <span name="email" class="text-red-600 text-sm"></span>

      <!-- password -->
      <div class="relative">
        <input
          required
          v-model="store.password"
          name="password"
          :type="inputType"
          class="shadow-sm fadeIn second" 
          placeholder="Senha"
          @keyup.enter="register"
        />

        <label
          @click="togglePasswordVisibility"
          class="absolute top-1/2 transform -translate-y-1/2 cursor-pointer"
          :class="[
            isMobile ? 'right-8' : 'right-12'
          ]"
        >
          <i v-if="showPassword" class="fas fa-eye text-gray-600"></i>
          <i v-else class="fas fa-eye-slash text-gray-600"></i>
        </label>
      </div>
      <span name="password" class="text-red-600 text-sm"></span>

      <!-- company_name -->
      <input
        type="text"
        class="fadeIn third"
        placeholder="Nome da empresa"
        v-model="store.company_name"
        name="company_name"
      />
      <br>
      <span name="company_name" class="text-red-600 text-sm"></span>

      <div class="p-2 flex justify-center fadeIn fourth">
        <CreateButton
          class="mt-5"
          @click="register()"
          :label="'Registrar'"
          :icon="'fa-solid fa-arrow-right-to-bracket'"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import CreateButton from "@/components/Buttons/Create.vue";
import AuthService from "../services/auth.service";
import { setFormFieldsError, validateFormField } from "@/helpers/formFieldsHandler";
import { useToast } from 'vue-toastification';
import { computed, onMounted, ref } from "vue";
import { dataAuth } from "../store";
import { storeApp } from '@/store';
import * as yup from '@/validator'
import router from "@/router";
import { useRoute } from "vue-router";

let store = dataAuth.state.register

const route = useRoute()
const toast = useToast()
const appImage = require("@/assets/logo/app.png")

// EU TE AMOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOO
// AMO SEUU ARROZ , SUA BATATA E SUA LINGUIÇA

const showPassword = ref(false)
const inputType = computed(() => (showPassword.value ? 'text' : 'password'))
const isMobile = computed(() => window.innerWidth < 768);

const togglePasswordVisibility = () => {
  showPassword.value = !showPassword.value
}

const form = yup.object().shape({
  email: yup.string().required().label('Email').max(150),
  password: yup.string().required().label('Senha').max(40),
  user_name: yup.string().required('Você precisa nos informar seu nome').label('Seu nome').max(150),
  company_name: yup.string().required('Informe o nome da sua empresa').label('Nome da empresa').max(150)
})

async function register() {
  storeApp.state.loading = true

  const validate = (await validateFormField(form, store))

  if (validate && validate.length > 0) {
    setFormFieldsError(validate)
    storeApp.state.loading = false
    return
  }

  if(route.query.indicatedBy) {
    store.indicated_by = route.query.indicatedBy
  }

  await AuthService.register(store)
    .then(response => {
      toast(response.data.message, {
        toastClassName: 'toast-success'
      })

      AuthService.login({email: store.email, password: store.password})
        .then((res) => {
          storeApp.dispatch('showMenuUserLogged')

          const userPermissions = [...res.data.user.permissions].map(
            (el) => el.key
          )

          localStorage.setItem('__permissions', userPermissions)
          localStorage.setItem('__access', res.data.access_token)
          localStorage.setItem('__uuid', res.data.user.id)
          localStorage.setItem('__user_name', res.data.user.name)
          localStorage.setItem('__company', res.data.user.companies.id)

          if (res.data.user.companies.photo !== null || res.data.user.companies.photo !== 'null') { 
            storeApp.dispatch('setCompanyLogo', res.data.user.companies.photo) 
          } else {
            const appDefault = require('@/assets/logo/app.png')
            storeApp.dispatch('setCompanyLogo', appDefault) 
          }

          storeApp.state.loading = false

          toast('Bem vindo', {
            toastClassName: 'toast-success'
          })

          dataAuth.dispatch('restorePasswordResetFields')
          router.push('/')
        })
    }).catch(err => {
      storeApp.state.loading = false
      
      if (err?.response?.status && err?.response?.status === 400) {
        return toast(err.response.data.message, {
          toastClassName: 'toast-error'
        })
      }

      return toast('Algo deu errado ao cadastrar', { 
        toastClassName: 'toast-error' 
      })
    })
}

onMounted(() => {
  if(route.query.indicatedBy) {
    store.indicated_by = route.query.indicatedBy
    // alert(store.indicated_by);
  }
  
})

</script>

<style lang="scss" scoped>
input[type="button"],
input[type="submit"],
input[type="reset"] {
  background-color: #56baed;
  border: none;
  color: white;
  padding: 15px 80px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  text-transform: uppercase;
  font-size: 13px;
  -webkit-box-shadow: 0 10px 30px 0 rgba(95, 186, 233, 0.4);
  box-shadow: 0 10px 30px 0 rgba(95, 186, 233, 0.4);
  -webkit-border-radius: 5px 5px 5px 5px;
  border-radius: 5px 5px 5px 5px;
  margin: 5px 20px 40px 20px;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  &:hover {
    background-color: #39ace7;
  }
  &:active {
    -moz-transform: scale(0.95);
    -webkit-transform: scale(0.95);
    -o-transform: scale(0.95);
    -ms-transform: scale(0.95);
    transform: scale(0.95);
  }
}
input[type="text"],
input[type="password"],
input[type="email"] {
  background-color: #f6f6f6;
  border: none !important;
  color: #0d0d0d;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 5px;
  width: 85%;
  border: 2px solid #f6f6f6;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  -webkit-border-radius: 5px 5px 5px 5px;
  border-radius: 5px 5px 5px 5px;
  &:focus {
    background-color: #fff;
    border-bottom: 2px solid #5fbae9;
  }
  &::placeholder {
    color: rgba(0, 0, 0, 0.357);
  }
}
</style>

import ClientsView from '../ClientsView.vue'
import ClientsRegister from '../layouts/Register.vue'
import ClientsUpdate from '../layouts/Update.vue'

export const clientRoutes = [
  {
    path: '/clients',
    name: 'clients',
    component: ClientsView,
    beforeEnter: (to, from, next) => {
      if (!localStorage.__access) next({ name: 'login' })
      next()
    },
    meta: {
      key: 'view_clients'
    }
  },
  {
    path: '/clients/new',
    name: 'clients-new',
    component: ClientsRegister,
    beforeEnter: (to, from, next) => {
      if (!localStorage.__access) next({ name: 'login' })
      next()
    },
    meta: {
      key: 'add_client'
    }
  },
  {
    path: '/client/edit/:id',
    name: 'client-edit',
    component: ClientsUpdate,
    beforeEnter: (to, from, next) => {
      if (!localStorage.__access) next({ name: 'login' })
      next()
    },
    meta: {
      key: 'edit_client'
    }
  }
]

<template>
    <div class="flex items-center justify-center p-0 md:p-2">
      <div class="container max-w-screen-lg mx-auto">
        <div>
          <div class="bg-white rounded-2xl shadow-lg p-4 px-4 md:p-8 mb-6">
            <div class="grid gap-4 gap-y-2 text-sm grid-cols-1 lg:grid-cols-3">
              <div class="text-left block mb-4">
                <p class="mb-4 font-medium text-lg text-gray-800">
                  {{ descriptionTitle }}
                </p>
                <p class="text-gray-500">
                  Campos com <span class="text-red-500">*</span> são obrigatórios
                </p>
              </div>

              <div id="form-fields" class="lg:col-span-2">
                <div
                  class="grid gap-4 gap-y-2 text-sm grid-cols-1 md:grid-cols-5"
                >
                  <div class="md:col-span-3">
                    <label class="text-left flex pl-1" for="name">
                      Descrição <a class="text-red-600 ml-1">*</a>
                    </label>
                    <div
                      class="h-10 bg-gray-50 flex border border-gray-200 rounded items-center mt-1"
                    >
                      <input
                        v-model="store.form.name"
                        type="text"
                        name="name"
                        class="px-1 py-2 appearance-none outline-none text-gray-800 w-full bg-transparent"
                        placeholder="Informe o custo"
                      />
                      <CleanInputButton
                        @click="store.form.name = null"
                      />
                    </div>

                    <span name="form.name" class="text-red-600 text-sm" />
                  </div>

                  <div class="md:col-span-2">
                    <label class="text-left flex pl-1" for="amount">
                      Valor <a class="text-red-600 ml-1">*</a>
                    </label>
                    <div
                      class="h-10 bg-gray-50 flex border border-gray-200 rounded items-center mt-1"
                    >
                      <input
                        type="text"
                        v-model="store.form.amount"
                        @input="formatCurrency"
                        placeholder="R$ 0,00"
                        name="amount"
                        class="px-1 py-2 appearance-none outline-none text-gray-800 w-full bg-transparent"
                      />
                      <CleanInputButton
                        @click="store.form.amount = null"
                      />
                    </div>

                    <span name="form.amount" class="text-red-600 text-sm" />
                  </div>
                </div>

                <div @click="toggleAdvancedFields" class="cursor-pointer mt-4">
                  <div class="flex justify-between items-center bg-gray-100 rounded p-1">
                    <label class="font-medium text-lg text-gray-800 p-1 -ml-1">
                      Opções Avançadas
                    </label>
                    <svg
                      :class="{'rotate-180': showAdvancedFields}"
                      class="w-6 h-6 transition-transform transform mr-2"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M19 9l-7 7-7-7"
                      ></path>
                    </svg>
                  </div>
                </div>

                <Transition mode="out-in">
                  <div v-show="showAdvancedFields" class="grid gap-4 gap-y-2 text-sm grid-cols-1 md:grid-cols-5 mt-2">

                    <div class="md:col-span-2">
                      <label class="text-left flex pl-1" for="facebook">
                        Tipo do Custo
                      </label>
                      <div
                        class="h-10 bg-gray-50 flex border border-gray-200 rounded items-center mt-1"
                      >
                        <SimpleSelect
                          class="px-1 py-2 appearance-none outline-none text-gray-800 w-full bg-transparen"
                          :data="types"
                          @selected-data="(val) => (store.form.type = val)"
                        />
                      </div>
                    </div>
                    
                    <div class="md:col-span-2">
                      <label class="text-left flex pl-1" for="instagram">
                        Categoria
                      </label>

                      <div
                        class="h-10 bg-gray-50 flex border border-gray-200 rounded items-center mt-1"
                      >
                        <SimpleSelect
                          class="px-1 py-2 appearance-none outline-none text-gray-800 w-full bg-transparen"
                          :data="category"
                          @selected-data="(val) => (store.form.category = val)"
                        />
                      </div>
                    </div>

                    <div class="md:col-span">
                      <label class="text-left flex pl-1" for="facebook">
                        Data de saída
                      </label>
                      <div
                        class="h-10 bg-gray-50 flex border border-gray-200 rounded items-center mt-1"
                      >
                        <input
                          v-model="store.form.date"
                          type="date"
                          name="date"
                          class="px-1 appearance-none outline-none text-gray-800 w-full bg-transparent"
                        />
                      </div>
                    </div>

                    <div class="md:col-span-2">
                      <label class="text-left flex pl-1" for="facebook">
                        Forma de pagamento
                      </label>
                      <div
                        class="h-10 bg-gray-50 flex border border-gray-200 rounded items-center mt-1"
                      >
                        <SimpleSelect
                          class="px-1 py-2 appearance-none outline-none text-gray-800 w-full bg-transparen"
                          :data="payments"
                          @selected-data="(val) => (store.form.payment = val)"
                        />
                      </div>
                    </div>

                    <div class="md:col-span-3">
                      <label class="text-left flex pl-1" for="observation">
                        Observações
                      </label>
                      <div
                        class="h-10 bg-gray-50 flex border border-gray-200 rounded items-center mt-1"
                      >
                        <input
                          v-model="store.form.observation"
                          name="observation"
                          class="px-1 appearance-none outline-none text-gray-800 w-full bg-transparent"
                        />
                        <CleanInputButton
                          @click="store.form.observation = null"
                        />
                      </div>
                    </div>

                  </div>
                </Transition>
              </div>
            </div>
            <div class="flex justify-center mt-8">
              <slot />
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>

<script>
import CleanInputButton from '../components/utils/CleanInputButton.vue'
import SimpleSelect from '@/components/Select/SimpleSelect.vue'
import { useToast } from 'vue-toastification'
import { dataCosts } from '../store'

export default {
  name: 'FormVue',
  components: {
    CleanInputButton,
    SimpleSelect
  },
  props: {
    descriptionTitle: String
  },
  data () {
    const store = dataCosts.state
    const toast = useToast()
    const types = [
      {
        name: 'EMPRESA',
        value: 'business_cost'
      },
      {
        name: 'PESSOAL',
        value: 'personal'
      },
      {
        name: 'DIVERSOS',
        value: 'general'
      },
    ]
    const category = [
      {
        name: 'EQUIPAMENTOS',
        value: 'equipment'
      },
      {
        name: 'PRODUTOS',
        value: 'products'
      },
      {
        name: 'FUNCIONÁRIOS',
        value: 'employees'
      },
      {
        name: 'ENERGIA',
        value: 'energy_bill'
      },
      {
        name: 'AGUÁ',
        value: 'water_bill'
      },
      {
        name: 'INTERNET',
        value: 'internet_bill'
      },
      {
        name: 'ALUGUEL',
        value: 'rent'
      },
      {
        name: 'CONTA',
        value: 'account'
      },
      {
        name: 'MANUTENÇÃO',
        value: 'maintenance'
      },
      {
        name: 'INFRAESTRUTURA',
        value: 'infra'
      },
      {
        name: 'TREINAMENTOS',
        value: 'trainings'
      },
      {
        name: 'CURSOS',
        value: 'courses'
      },
      {
        name: 'MARKETING',
        value: 'marketing'
      },
      {
        name: 'VIAGENS',
        value: 'journey'
      },
      {
        name: 'ASSINATURAS',
        value: 'signatures'
      },
      {
        name: 'OUTROS',
        value: 'others'
      }
    ]
    const payments = [
      {
        name: 'CARTÃO DE CRÉDITO',
        value: 'credit_card'
      },
      {
        name: 'CARTÃO DE DÉBITO',
        value: 'debit_card'
      },
      {
        name: 'PIX',
        value: 'pix'
      },
      {
        name: 'DINHEIRO',
        value: 'money'
      }
    ]

    return {
      store,
      toast,
      showAdvancedFields: false,
      types,
      category,
      payments,
    }
  },
  methods: {
    toggleAdvancedFields () {
      this.showAdvancedFields = !this.showAdvancedFields
    },
    formatCurrency (event) {
      let rawPrice = ''
      const onlyDigits = event.target.value.replace(/\D/g, '')

      const formatted = new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL'
      }).format(onlyDigits / 100)

      this.store.form.amount = formatted
      rawPrice = onlyDigits / 100
    }
  }
}
</script>

<style lang="scss" scoped>
.v-enter-from,
.v-leave-to {
  transform: translate(50px);
  opacity: 0;
}

.v-enter-active,
.v-leave-active {
  transition: all 0.3s;
}
</style>

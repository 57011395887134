import { http } from '@/http'

const PATH = 'app/costs/'

class CostsService {
  async getDataChart() {
    return http.get(PATH.concat('resume'))
  }

  async getDataCosts () {
    return http.get(PATH.concat('all'))
  }

  async registerCost (data) {
    return http.post(PATH.concat('register'), data, {
      headers: {
        __user: localStorage.getItem('__uuid')
      }
    })
  }

  async removeCosts (id) {
    return http.delete(PATH.concat(id).concat('/remove'), {
      headers: {
        __user: localStorage.getItem('__uuid')
      }
    })
  }
}

export default new CostsService()

<template>
  <div class="relative inline-block">
    <button
      @click="toggleModal"
      class="bg-gradient-to-r from-[#1e7c84] to-[#1accdd] text-white p-2 rounded-full ml-2 hover:shadow-lg hover:-translate-y-0.5 hover:-translate-x-0.5 transition-all"
    >
      <i class="fa-solid fa-filter p-1"></i>
    </button>

    <div v-if="isModalOpen" class="absolute right-0 mt-2 w-64 bg-white shadow-lg rounded-lg p-2 z-10">
      <div v-if="!selectedOption">
        <h2 class="text-lg mb-2">Filtrar por</h2>
        
        <button
          @click="selectOption('Registrado por', 'user')"
          class="block w-full text-left mb-2 p-2 bg-gray-100 rounded hover:shadow-lg hover:-translate-y-1 hover:-translate-x-1 transition-all"
        >
          <div class="flex items-center justify-start">
            <i class="fa-solid fa-user-tie p-2"></i>
            <span class="flex-grow text-center">Registrado por</span>
          </div>
        </button>

        <button
          @click="selectOption('Descrição', 'name')"
          class="block w-full text-left mb-2 p-2 bg-gray-100 rounded hover:shadow-lg hover:-translate-y-1 hover:-translate-x-1 transition-all"
        >
          <div class="flex items-center justify-start">
            <i class="fa-solid fa-boxes-stacked p-2"></i>
            <span class="flex-grow text-center">
              Descrição
            </span>
          </div>
        </button>

        <button
          @click="selectOption('Valor', 'amount')"
          class="block w-full text-left mb-2 p-2 bg-gray-100 rounded hover:shadow-lg hover:-translate-y-1 hover:-translate-x-1 transition-all"
        >
          <div class="flex items-center justify-start">
            <i class="fas fa-user p-2"></i>
            <span class="flex-grow text-center">
              Valor
            </span>
          </div>
        </button>

        <button
          @click="selectOption('Tipo', 'type')"
          class="block w-full text-left mb-2 p-2 bg-gray-100 rounded hover:shadow-lg hover:-translate-y-1 hover:-translate-x-1 transition-all"
        >
          <div class="flex items-center justify-start">
            <i class="fas fa-user p-2"></i>
            <span class="flex-grow text-center">
              Tipo
            </span>
          </div>
        </button>

        <button
          @click="selectOption('Categoria', 'category')"
          class="block w-full text-left mb-2 p-2 bg-gray-100 rounded hover:shadow-lg hover:-translate-y-1 hover:-translate-x-1 transition-all"
        >
          <div class="flex items-center justify-start">
            <i class="fas fa-user p-2"></i>
            <span class="flex-grow text-center">
              Categoria
            </span>
          </div>
        </button>

        <button
          @click="selectOption('Pagamento', 'payment')"
          class="block w-full text-left mb-2 p-2 bg-gray-100 rounded hover:shadow-lg hover:-translate-y-1 hover:-translate-x-1 transition-all"
        >
          <div class="flex items-center justify-start">
            <i class="fas fa-user p-2"></i>
            <span class="flex-grow text-center">
              Pagamento via
            </span>
          </div>
        </button>

      </div>

      <div v-else>
        <h2 class="text-lg mb-4">{{ selectedOption }}</h2>
        <input
          type="text"
          v-model="dataFilter"
          :placeholder="'Informe o ' + selectedOption"
          class="w-full p-2 mb-4 border border-gray-300 rounded"
        />

        <div class="flex font-semibold justify-center">
          <button @click="cancel" class="bg-gray-300 text-black p-2 rounded mr-2 hover:shadow-lg hover:-translate-y-1 hover:-translate-x-1 transition-all">
            Cancelar
          </button>

          <button @click="apply" class="bg-gradient-to-r from-[#1e7c84] to-[#1accdd] text-white p-2 rounded hover:shadow-lg hover:-translate-y-1 hover:-translate-x-1 transition-all">
            Aplicar
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import { dataCosts } from '../../store'

const selectedOption = ref(null)
const isModalOpen = ref(false)
const store = dataCosts
const dataFilter = ref(null)
const field = ref('')

const toggleModal = () => {
  isModalOpen.value = !isModalOpen.value
}

const selectOption = (option, key) => {
  field.value = key.charAt(0).toUpperCase() + key.slice(1)
  
  selectedOption.value = option
}

const cancel = () => {
  // isModalOpen.value = false;
  selectedOption.value = null
}

const apply = () => {
  store.dispatch(`updateFilter${field.value}`, dataFilter.value)

  dataFilter.value = null
  isModalOpen.value = false
  selectedOption.value = null
}

</script>
